import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_application_exceptions_approval = _resolveComponent("application-exceptions-approval", true)!

  return (_openBlock(), _createBlock(_component_application_exceptions_approval, {
    exceptionId: _ctx.exceptionId,
    backRouteLocation: _ctx.assessmentsSummaryRoute,
    processing: _ctx.processing,
    showStaffApproval: true,
    onSubmitted: _ctx.submitted
  }, null, 8, ["exceptionId", "backRouteLocation", "processing", "onSubmitted"]))
}