import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_application_offering_change = _resolveComponent("status-chip-application-offering-change")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Institution requests",
        subTitle: "Applications"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Requested application changes",
        recordsCount: _ctx.applications?.count
      }, {
        subtitle: _withCtx(() => [
          _createTextVNode(" Make a determination on a requested change for a program and offering in an application. ")
        ]),
        actions: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            density: "compact",
            label: "Search name or application #",
            variant: "outlined",
            modelValue: _ctx.searchCriteria,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria) = $event)),
            onKeyup: _withKeys(_ctx.searchApplicationOfferingChangeRecords, ["enter"]),
            "prepend-inner-icon": "mdi-magnify",
            "hide-details": "auto"
          }, null, 8, ["modelValue", "onKeyup"])
        ]),
        _: 1
      }, 8, ["recordsCount"]),
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.applications?.count
          }, {
            default: _withCtx(() => [
              (_ctx.applications?.count)
                ? (_openBlock(), _createBlock(_component_v_data_table_server, {
                    key: 0,
                    headers: _ctx.AllInProgressOfferingChangeSummaryHeaders,
                    items: _ctx.applications?.results,
                    "items-length": _ctx.applications.count,
                    loading: _ctx.loading,
                    "item-value": "applicationId",
                    "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                    "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.DEFAULT_PAGE_LIMIT) = $event)),
                    "items-per-page-options": _ctx.ITEMS_PER_PAGE,
                    "onUpdate:options": _ctx.paginationAndSortEvent
                  }, {
                    [`item.dateSubmitted`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(item.createdAt)), 1)
                    ]),
                    [`item.fullName`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.fullName), 1)
                    ]),
                    [`item.applicationNumber`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.applicationNumber), 1)
                    ]),
                    [`item.status`]: _withCtx(({ item }) => [
                      _createVNode(_component_status_chip_application_offering_change, {
                        status: item.status
                      }, null, 8, ["status"])
                    ]),
                    [`item.id`]: _withCtx(({ item }) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: ($event: any) => (_ctx.viewAssessment(item))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["headers", "items", "items-length", "loading", "items-per-page", "items-per-page-options", "onUpdate:options"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}