import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_application_header_title = _resolveComponent("application-header-title")!
  const _component_notice_of_assessment_form_view = _resolveComponent("notice-of-assessment-form-view")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessment",
        subTitle: "Notice of Assessment",
        routeLocation: {
          name: _ctx.AESTRoutesConst.ASSESSMENT_AWARD_VIEW,
          params: { applicationId: _ctx.applicationId, studentId: _ctx.studentId, assessmentId: _ctx.assessmentId },
        }
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_application_header_title, { "application-id": _ctx.applicationId }, null, 8, ["application-id"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_notice_of_assessment_form_view, {
        "assessment-id": _ctx.assessmentId,
        "can-reissue-m-s-f-a-a": _ctx.hasStudentReissueMSFAARole,
        onReissueMSFAA: _ctx.reissueMSFAA
      }, null, 8, ["assessment-id", "can-reissue-m-s-f-a-a", "onReissueMSFAA"])
    ]),
    _: 1
  }))
}