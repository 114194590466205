import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, { enableCardView: true }, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Part-Time monthly loan balance",
        subTitle: "Balance of part-time Canada Student Loan outstanding between the student and NSLSC at any given time.\n        This amount is updated once per month, and affects a student's eligibility for future funding, as a student \n        cannot exceed the maximum limit in total outstanding balance."
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.studentLoanBalanceDetails.length
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.studentLoanBalanceDetails,
                class: "p-m-4",
                paginator: true,
                rows: _ctx.pageLimit,
                rowsPerPageOptions: _ctx.PAGINATION_LIST
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "balanceDate",
                    header: "Date"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.balanceDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "cslBalance",
                    header: "CSLP Balance"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.formatCurrency(slotProps.data.cslBalance)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "rowsPerPageOptions"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}