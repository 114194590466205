import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "primary",
        class: "float-right",
        disabled: _ctx.sinValidStatus !== _ctx.SINStatusEnum.VALID,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToStudentApplication())),
        "prepend-icon": "fa:fa fa-edit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Start New Application ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}