import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_banner = _resolveComponent("banner")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Programs",
        routeLocation: _ctx.getRouteLocation,
        subTitle: _ctx.subTitle
      }, null, 8, ["routeLocation", "subTitle"])
    ]),
    alerts: _withCtx(() => [
      (_ctx.programData.hasOfferings)
        ? (_openBlock(), _createBlock(_component_banner, {
            key: 0,
            type: _ctx.BannerTypes.Success,
            header: "Program details no longer editable",
            summary: "This program has study period offerings attached to it, and the program details can no longer be edited. If revisions are required other than to program name or program description, you must create a new program with the correct details."
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "success",
                onClick: _ctx.createNewProgram,
                class: "btn-font-color-light"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create program ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "educationProgram",
        formData: _ctx.programData,
        onSubmitted: _ctx.submitted
      }, _createSlots({ _: 2 }, [
        (!_ctx.programData.isReadonly)
          ? {
              name: "actions",
              fn: _withCtx(({ submit }) => [
                _createVNode(_component_footer_buttons, {
                  processing: _ctx.processing,
                  primaryLabel: "Submit",
                  onPrimaryClick: submit,
                  onSecondaryClick: _ctx.goBack
                }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["formData", "onSubmitted"])
    ]),
    _: 1
  }))
}