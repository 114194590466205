import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCLogo = _resolveComponent("BCLogo")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_IdleTimeChecker = _resolveComponent("IdleTimeChecker")!

  return (_openBlock(), _createBlock(_component_IdleTimeChecker, {
    clientIdType: _ctx.ClientIdType.SupportingUsers
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        dense: "",
        flat: "",
        app: "",
        style: {"overflow":"visible"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BCLogo, {
            subtitle: "Supporting Information for Student Applications",
            onClick: _ctx.goToDashboard
          }, null, 8, ["onClick"]),
          _createVNode(_component_v_spacer),
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "mr-5",
                text: "",
                onClick: _ctx.goToDashboard,
                "prepend-icon": "mdi-home-outline"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Home")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_v_menu, { key: 1 }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({
                    class: "mr-5",
                    icon: "fa:fa fa-user",
                    variant: "outlined",
                    elevation: "1",
                    color: "secondary"
                  }, props, { "aria-label": "Account" }), null, 16)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        value: _ctx.menuItem.label
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                onClick: _ctx.menuItem.command
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.menuItem.label), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, { name: "sidebar" }),
      _createVNode(_component_v_main, { class: "body-background" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["clientIdType"]))
}