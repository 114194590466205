import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!

  return (_openBlock(), _createBlock(_component_modal_dialog_base, {
    title: "Edit application",
    showDialog: _ctx.showDialog
  }, {
    content: _withCtx(() => [
      _createTextVNode(" Any edits made to your application may require the resubmission of supporting information, potentially delaying your application. Are you sure you want to proceed? ")
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_footer_buttons, {
        primaryLabel: "Edit application",
        secondaryLabel: "No",
        onPrimaryClick: _ctx.editApplication,
        onSecondaryClick: _ctx.dialogClosed
      }, null, 8, ["onPrimaryClick", "onSecondaryClick"])
    ]),
    _: 1
  }, 8, ["showDialog"]))
}