import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_student_document_list = _resolveComponent("student-document-list")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_student_page_container = _resolveComponent("student-page-container")!

  return (_openBlock(), _createBlock(_component_student_page_container, { "layout-template": "centered" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Student",
        subTitle: "File uploader"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_formio_container, {
                        formName: "uploadStudentDocuments",
                        onSubmitted: _ctx.submitForm
                      }, {
                        actions: _withCtx(({ submit }) => [
                          _createVNode(_component_footer_buttons, {
                            processing: _ctx.processing,
                            onPrimaryClick: submit,
                            "primary-label": "Submit documents",
                            "show-secondary-button": false
                          }, null, 8, ["processing", "onPrimaryClick"])
                        ]),
                        _: 1
                      }, 8, ["onSubmitted"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { md: "5" }, {
            default: _withCtx(() => [
              _createVNode(_component_student_document_list, { reload: _ctx.reloadDocuments }, null, 8, ["reload"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}