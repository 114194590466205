import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!

  return (_openBlock(), _createBlock(_component_formio_container, {
    formName: "institutionLocation",
    formData: _ctx.locationData,
    onSubmitted: _ctx.updateInstitutionLocation
  }, {
    actions: _withCtx(({ submit }) => [
      _createVNode(_component_footer_buttons, {
        processing: _ctx.processing,
        primaryLabel: "Submit",
        onPrimaryClick: submit,
        showSecondaryButton: false
      }, null, 8, ["processing", "onPrimaryClick"])
    ]),
    _: 1
  }, 8, ["formData", "onSubmitted"]))
}