import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!

  return (_openBlock(), _createBlock(_component_formio_container, {
    formName: "designationAgreementDetails",
    formData: _ctx.model,
    readOnly: _ctx.viewOnly,
    onSubmitted: _ctx.submitDesignation,
    onRender: _ctx.formRender
  }, _createSlots({ _: 2 }, [
    (!_ctx.viewOnly)
      ? {
          name: "actions",
          fn: _withCtx(({ submit }) => [
            _createVNode(_component_footer_buttons, {
              processing: _ctx.processing,
              primaryLabel: "Submit",
              onPrimaryClick: ($event: any) => (submit()),
              onSecondaryClick: _ctx.cancel
            }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["formData", "readOnly", "onSubmitted", "onRender"]))
}