import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_related_application_changed = _resolveComponent("related-application-changed")!
  const _component_multiple_disbursement_banner = _resolveComponent("multiple-disbursement-banner")!
  const _component_disbursement_banner = _resolveComponent("disbursement-banner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.applicationCOEDetails.assessmentTriggerType ===
      _ctx.AssessmentTriggerType.RelatedApplicationChanged
    )
      ? (_openBlock(), _createBlock(_component_related_application_changed, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.applicationCOEDetails?.secondDisbursement)
      ? (_openBlock(), _createBlock(_component_multiple_disbursement_banner, {
          key: 1,
          firstCOEStatus: _ctx.applicationCOEDetails?.firstDisbursement?.coeStatus,
          secondCOEStatus: _ctx.applicationCOEDetails?.secondDisbursement?.coeStatus,
          coeDenialReason: _ctx.multipleCOEDenialReason
        }, null, 8, ["firstCOEStatus", "secondCOEStatus", "coeDenialReason"]))
      : (_openBlock(), _createBlock(_component_disbursement_banner, {
          key: 2,
          coeStatus: _ctx.applicationCOEDetails?.firstDisbursement?.coeStatus,
          coeDenialReason: _ctx.applicationCOEDetails?.firstDisbursement?.coeDenialReason
        }, null, 8, ["coeStatus", "coeDenialReason"]))
  ], 64))
}