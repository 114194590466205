import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "label-bold" }
const _hoisted_3 = { class: "mb-2 label-value muted-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.propertyTitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "value", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.propertyValue), 1)
      ])
    ])
  ], 64))
}