import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/bc_gov_logo_header.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", null, [
      _createVNode(_component_v_img, {
        onClick: _ctx.logoClick,
        class: "ml-5",
        width: "57px",
        height: "40px",
        src: _imports_0,
        alt: "StudentAid BC logo"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_v_container, {
      class: "p-0 ml-2",
      onClick: _ctx.logoClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          class: "logo-main-text",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createTextVNode("StudentAid BC")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, {
          class: "logo-subtitle hidden-xs",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}