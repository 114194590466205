import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_tooltip_icon = _resolveComponent("tooltip-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_designation_agreement_summary = _resolveComponent("designation-agreement-summary")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage institution",
        subTitle: "Manage Designations",
        "data-cy": "manageDesignationHeader"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Designation requests",
        recordsCount: _ctx.designations.length
      }, {
        subtitle: _withCtx(() => [
          _createTextVNode(" Ensure you have an approved designation to administer financial aid to students "),
          _createVNode(_component_tooltip_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("You must have the role of a Legal Signing Authority to request a designation.")
            ]),
            _: 1
          })
        ]),
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "ml-2 float-right",
            color: "primary",
            "data-cy": "requestDesignation",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToRequestDesignation())),
            "prepend-icon": "fa:fa fa-bell-concierge",
            disabled: !_ctx.isLegalSigningAuthority
          }, {
            default: _withCtx(() => [
              _createTextVNode("Request designation")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["recordsCount"]),
      _createVNode(_component_designation_agreement_summary, {
        designations: _ctx.designations,
        toggleMessage: "You don't have any agreements yet",
        onViewDesignation: _ctx.goToViewDesignation
      }, null, 8, ["designations", "onViewDesignation"])
    ]),
    _: 1
  }))
}