import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (
      _ctx.coeApprovalPeriodStatus !== _ctx.COEApprovalPeriodStatus.WithinApprovalPeriod
    )
    ? (_openBlock(), _createBlock(_component_banner, {
        key: 0,
        class: "mb-2",
        type: _ctx.BannerTypes.Warning,
        header: _ctx.header,
        summary: _ctx.summary
      }, null, 8, ["type", "header", "summary"]))
    : _createCommentVNode("", true)
}