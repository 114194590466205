import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Program info requests",
        routeLocation: _ctx.goBackRouteParams,
        subTitle: "View Application"
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "programInformationRequest",
        formData: _ctx.initialData,
        onLoaded: _ctx.formLoaded,
        onChanged: _ctx.formChanged,
        onSubmitted: _ctx.submitted,
        onCustomEvent: _ctx.customEventCallback
      }, {
        actions: _withCtx(({ submit }) => [
          _createVNode(_component_footer_buttons, {
            processing: _ctx.processing,
            primaryLabel: "Complete program info request",
            onPrimaryClick: submit,
            onSecondaryClick: _ctx.goBack
          }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick"])
        ]),
        _: 1
      }, 8, ["formData", "onLoaded", "onChanged", "onSubmitted", "onCustomEvent"])
    ]),
    _: 1
  }))
}