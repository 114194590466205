import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_offering_application_banner = _resolveComponent("offering-application-banner")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_offering_form = _resolveComponent("offering-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_assess_offering_change_modal = _resolveComponent("assess-offering-change-modal")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered-card-tab" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Study period offerings",
        routeLocation: { name: _ctx.AESTRoutesConst.OFFERING_CHANGE_REQUESTS },
        subTitle: "View Request"
      }, {
        buttons: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.InstitutionApproveDeclineOfferingChanges
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_v_btn, {
                color: "primary",
                variant: "outlined",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.assessOfferingChange(_ctx.OfferingStatus.ChangeDeclined))),
                disabled: notAllowed
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Decline reassessment")
                ]),
                _: 2
              }, 1032, ["disabled"]),
              _createVNode(_component_v_btn, {
                class: "ml-2",
                color: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.assessOfferingChange(_ctx.OfferingStatus.Approved))),
                disabled: notAllowed
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Approve reassessment")
                ]),
                _: 2
              }, 1032, ["disabled"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["routeLocation"]),
      _createVNode(_component_program_offering_detail_header, {
        class: "m-4",
        headerDetails: _ctx.headerDetails
      }, null, 8, ["headerDetails"])
    ]),
    alerts: _withCtx(() => [
      _createVNode(_component_offering_application_banner, { offeringId: _ctx.offeringId }, null, 8, ["offeringId"])
    ]),
    "tab-header": _withCtx(() => [
      _createVNode(_component_v_tabs, {
        stacked: "",
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, {
            value: "requested-change",
            ripple: false
          }, {
            default: _withCtx(() => [
              _createTextVNode("Requested Change")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, {
            value: "active-offering",
            ripple: false
          }, {
            default: _withCtx(() => [
              _createTextVNode("Active Offering")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_window_item, { value: "requested-change" }, {
            default: _withCtx(() => [
              _createVNode(_component_offering_form, { offeringId: _ctx.offeringId }, null, 8, ["offeringId"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_window_item, { value: "active-offering" }, {
            default: _withCtx(() => [
              _createVNode(_component_offering_form, {
                offeringId: _ctx.offering.precedingOfferingId
              }, null, 8, ["offeringId"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_assess_offering_change_modal, { ref: "assessOfferingChangeModalRef" }, null, 512)
    ]),
    _: 1
  }))
}