import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    app: "",
    color: "default",
    permanent: ""
  }, {
    append: _withCtx(() => [
      _createVNode(_component_v_list, {
        density: "compact",
        "active-class": "active-list-item",
        "bg-color": "default",
        class: "no-wrap",
        color: "primary",
        nav: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.AESTReports
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_v_list_item, {
                to: { name: _ctx.AESTRoutesConst.REPORTS },
                "prepend-icon": "mdi-content-copy",
                title: "Reports",
                disabled: notAllowed
              }, null, 8, ["to", "disabled"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        "active-class": "active-list-item",
        density: "compact",
        "bg-color": "default",
        color: "primary",
        class: "no-wrap",
        items: _ctx.menuItems
      }, null, 8, ["items"])
    ]),
    _: 1
  }))
}