import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_approve_application_offering_change_request_modal = _resolveComponent("approve-application-offering-change-request-modal")!
  const _component_decline_application_offering_change_request_modal = _resolveComponent("decline-application-offering-change-request-modal")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered-card-tab" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Financial Aid Application",
        "sub-title": "View Request",
        routeLocation: _ctx.goBackRouteParams
      }, _createSlots({ _: 2 }, [
        (
            _ctx.applicationOfferingChangeRequestStatus?.status ===
            _ctx.ApplicationOfferingChangeRequestStatus.InProgressWithStudent
          )
          ? {
              name: "buttons",
              fn: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  variant: "outlined",
                  onClick: _ctx.declineApplicationOfferingChangeRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Decline change")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  class: "ml-2",
                  onClick: _ctx.approveApplicationOfferingChangeRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Allow change")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["routeLocation"])
    ]),
    "tab-header": _withCtx(() => [
      _createVNode(_component_v_tabs, {
        stacked: "",
        color: "primary"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item.label,
              to: item.command(),
              ripple: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_approve_application_offering_change_request_modal, { ref: "approveApplicationOfferingChangeRequestModal" }, null, 512),
      _createVNode(_component_decline_application_offering_change_request_modal, { ref: "declineApplicationOfferingChangeRequestModal" }, null, 512)
    ]),
    _: 1
  }))
}