import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_program_info_request = _resolveComponent("status-chip-program-info-request")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: _ctx.locationName,
        subTitle: "Program Information Requests",
        "data-cy": "programInformationRequestsHeader"
      }, null, 8, ["title"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Active applications",
        "data-cy": "activeApplicationsTab",
        recordsCount: _ctx.applications.length
      }, null, 8, ["recordsCount"]),
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.applications.length
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                autoLayout: true,
                value: _ctx.applications,
                class: "p-m-4",
                paginator: true,
                rows: 10
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "fullName",
                    header: "Name"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(slotProps.data.fullName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "studyStartPeriod",
                    header: "Study Period"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyStartPeriod)) + " - " + _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyEndPeriod)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "applicationNumber",
                    header: "Application #"
                  }),
                  _createVNode(_component_Column, {
                    field: "pirStatus",
                    header: "Status"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_status_chip_program_info_request, {
                        status: slotProps.data.pirStatus
                      }, null, 8, ["status"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "applicationId",
                    header: ""
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: ($event: any) => (_ctx.goToViewApplication(slotProps.data.applicationId))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}