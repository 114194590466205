import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_start_application = _resolveComponent("start-application")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_applications = _resolveComponent("student-applications")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_student_page_container = _resolveComponent("student-page-container")!
  const _component_confirm_edit_application = _resolveComponent("confirm-edit-application")!
  const _component_cancel_application = _resolveComponent("cancel-application")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_student_page_container, { "full-width": true }, {
      header: _withCtx(() => [
        _createVNode(_component_header_navigator, {
          title: "Applications",
          subTitle: "My Applications"
        }, {
          buttons: _withCtx(() => [
            _createVNode(_component_start_application)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_student_applications, {
                  "manage-application": true,
                  "enable-view-application-on-name": true,
                  onEditApplicationAction: _ctx.editApplicationAction,
                  onOpenConfirmCancel: _ctx.confirmCancelApplication,
                  onGoToApplication: _ctx.goToApplication
                }, null, 8, ["onEditApplicationAction", "onOpenConfirmCancel", "onGoToApplication"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_confirm_edit_application, { ref: "editApplicationModal" }, null, 512),
    _createVNode(_component_cancel_application, { ref: "cancelApplicationModal" }, null, 512)
  ], 64))
}