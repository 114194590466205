import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tooltip_icon = _resolveComponent("tooltip-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "Applications",
            recordsCount: _ctx.applications?.count
          }, {
            subtitle: _withCtx(() => [
              _createTextVNode(" Request a change for a program and offering in an application "),
              _createVNode(_component_tooltip_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("Only applications in the \"completed status\" are shown below to request a change. ")
                ]),
                _: 1
              })
            ]),
            actions: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                label: "Search name or application #",
                variant: "outlined",
                modelValue: _ctx.searchCriteria,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria) = $event)),
                onKeyup: _withKeys(_ctx.searchApplicationOfferingChangeRecords, ["enter"]),
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "auto"
              }, null, 8, ["modelValue", "onKeyup"])
            ]),
            _: 1
          }, 8, ["recordsCount"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.applications?.count
              }, {
                default: _withCtx(() => [
                  (_ctx.applications?.count)
                    ? (_openBlock(), _createBlock(_component_v_data_table_server, {
                        key: 0,
                        headers: _ctx.AvailableToChangeOfferingChangeSummaryHeaders,
                        items: _ctx.applications?.results,
                        "items-per-page-options": _ctx.ITEMS_PER_PAGE,
                        "items-length": _ctx.applications?.count,
                        loading: _ctx.loading,
                        "item-value": "applicationId",
                        "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                        "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.DEFAULT_PAGE_LIMIT) = $event)),
                        "onUpdate:options": _ctx.paginationAndSortEvent
                      }, {
                        [`item.fullName`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.fullName), 1)
                        ]),
                        [`item.studyStartDate`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(item.studyStartDate)) + " - " + _toDisplayString(_ctx.dateOnlyLongString(item.studyEndDate)), 1)
                        ]),
                        [`item.applicationNumber`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.applicationNumber), 1)
                        ]),
                        [`item.applicationId`]: _withCtx(({ item }) => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: ($event: any) => (_ctx.requestAChange(item.applicationId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Request a change")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["headers", "items", "items-per-page-options", "items-length", "loading", "items-per-page", "onUpdate:options"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}