import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio = _resolveComponent("formio")!
  const _component_content_group = _resolveComponent("content-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studentAppealRequests, (appealRequest) => {
      return (_openBlock(), _createBlock(_component_content_group, {
        key: appealRequest.formName,
        class: "mb-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_formio, {
            formName: appealRequest.formName,
            data: appealRequest.data,
            readOnly: _ctx.readOnly,
            onLoaded: _ctx.appealFormLoaded
          }, null, 8, ["formName", "data", "readOnly", "onLoaded"]),
          _renderSlot(_ctx.$slots, "approval-form", {
            approval: appealRequest.approval
          })
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "actions", { submit: _ctx.submit })
    ])
  ], 64))
}