import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_assessment = _resolveComponent("request-assessment")!
  const _component_history_assessment = _resolveComponent("history-assessment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_assessment, {
      class: "mb-5",
      applicationId: _ctx.applicationId,
      showWhenEmpty: false,
      onViewStudentAppeal: _ctx.goToStudentAppeal,
      onViewStudentApplicationOfferingChange: 
      _ctx.goToStudentApplicationOfferingChangeRequest
    
    }, null, 8, ["applicationId", "onViewStudentAppeal", "onViewStudentApplicationOfferingChange"]),
    _createVNode(_component_history_assessment, {
      class: "mb-5",
      applicationId: _ctx.applicationId,
      viewRequestTypes: _ctx.studentAssessmentRequestTypes,
      onViewStudentAppeal: _ctx.goToStudentAppeal,
      onViewStudentApplicationOfferingChange: 
      _ctx.goToStudentApplicationOfferingChangeRequest
    ,
      onViewAssessment: _ctx.goToViewAssessment
    }, null, 8, ["applicationId", "viewRequestTypes", "onViewStudentAppeal", "onViewStudentApplicationOfferingChange", "onViewAssessment"])
  ], 64))
}