import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "color-blue pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_StudentApplication = _resolveComponent("StudentApplication")!
  const _component_full_page_container = _resolveComponent("full-page-container")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.applicationDetail.data)
      ? (_openBlock(), _createBlock(_component_full_page_container, { key: 0 }, {
          header: _withCtx(() => [
            _createVNode(_component_header_navigator, {
              title: "Back to student applications",
              routeLocation: {
          name: _ctx.InstitutionRoutesConst.STUDENT_APPLICATIONS,
          params: { studentId: _ctx.studentId },
        },
              subTitle: "Financial Aid Application"
            }, null, 8, ["routeLocation"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, " Student Application Details " + _toDisplayString(_ctx.applicationDetail.applicationNumber), 1),
            _createVNode(_component_StudentApplication, {
              selectedForm: _ctx.selectedForm,
              initialData: _ctx.initialData,
              programYearId: _ctx.applicationDetail.applicationProgramYearID,
              isReadOnly: true
            }, null, 8, ["selectedForm", "initialData", "programYearId"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}