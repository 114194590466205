import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!

  return (_openBlock(), _createBlock(_component_modal_dialog_base, {
    title: _ctx.title,
    dialogType: "warning",
    showDialog: _ctx.showDialog,
    maxWidth: _ctx.maxWidth
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_footer_buttons, {
        primaryLabel: _ctx.okLabel,
        secondaryLabel: _ctx.cancelLabel,
        onPrimaryClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resolvePromise(true))),
        onSecondaryClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resolvePromise(false))),
        disablePrimaryButton: _ctx.disablePrimaryButton,
        showSecondaryButton: _ctx.showSecondaryButton,
        processing: _ctx.loading
      }, null, 8, ["primaryLabel", "secondaryLabel", "disablePrimaryButton", "showSecondaryButton", "processing"])
    ]),
    _: 3
  }, 8, ["title", "showDialog", "maxWidth"]))
}