import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-text-capitalize" }
const _hoisted_2 = { class: "p-text-capitalize" }
const _hoisted_3 = { class: "p-text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_input = _resolveComponent("v-input")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_form, { ref: "searchStudentsForm" }, {
      default: _withCtx(() => [
        _createVNode(_component_content_group, { class: "mb-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  lg: "11"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Application number",
                              density: "compact",
                              "data-cy": "appNumber",
                              variant: "outlined",
                              modelValue: _ctx.appNumber,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.appNumber) = $event)),
                              onKeyup: _withKeys(_ctx.searchStudents, ["enter"]),
                              "hide-details": ""
                            }, null, 8, ["modelValue", "onKeyup"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "SIN",
                              density: "compact",
                              "data-cy": "sin",
                              variant: "outlined",
                              modelValue: _ctx.sin,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sin) = $event)),
                              onKeyup: _withKeys(_ctx.searchStudents, ["enter"]),
                              "hide-details": ""
                            }, null, 8, ["modelValue", "onKeyup"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Given names",
                              density: "compact",
                              "data-cy": "firstName",
                              variant: "outlined",
                              modelValue: _ctx.firstName,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                              onKeyup: _withKeys(_ctx.searchStudents, ["enter"]),
                              "hide-details": ""
                            }, null, 8, ["modelValue", "onKeyup"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: "Last name",
                              density: "compact",
                              "data-cy": "lastName",
                              variant: "outlined",
                              modelValue: _ctx.lastName,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
                              onKeyup: _withKeys(_ctx.searchStudents, ["enter"]),
                              "hide-details": ""
                            }, null, 8, ["modelValue", "onKeyup"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      class: "p-button-raised",
                      "data-cy": "searchStudents",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchStudents()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Search ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_input, {
              rules: [_ctx.isValidSearch()],
              "hide-details": "auto",
              error: ""
            }, null, 8, ["rules"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    (_ctx.studentsFound)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_body_header, { title: "Results" }),
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.students?.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DataTable, { value: _ctx.students }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "sin",
                        header: "SIN",
                        sortable: true
                      }, {
                        body: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(_ctx.sinDisplayFormat(slotProps.data.sin)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "firstName",
                        header: "Given name",
                        sortable: true
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_1, _toDisplayString(slotProps.data.firstName), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "lastName",
                        header: "Last name",
                        sortable: true
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(slotProps.data.lastName), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "birthDate",
                        header: "Date of birth"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.birthDate)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, { header: "Action" }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            class: "p-button-raised",
                            "data-cy": "viewStudent",
                            onClick: ($event: any) => (_ctx.$emit('goToStudentView', slotProps.data.id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("View")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}