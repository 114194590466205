import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_status_chip_requested_assessment = _resolveComponent("status-chip-requested-assessment")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.requestedAssessment.length || _ctx.showWhenEmpty)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_body_header, {
                title: "Unapproved changes",
                class: "m-1",
                subTitle: "Pending or declined requests submitted by the student or institution.",
                recordsCount: _ctx.requestedAssessment.length
              }, null, 8, ["recordsCount"]),
              _createVNode(_component_content_group, { class: "mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_toggle_content, {
                    toggled: !_ctx.requestedAssessment.length,
                    message: "No requests found."
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DataTable, {
                        value: _ctx.requestedAssessment,
                        paginator: true,
                        rows: _ctx.DEFAULT_PAGE_LIMIT,
                        rowsPerPageOptions: _ctx.PAGINATION_LIST,
                        totalRecords: _ctx.requestedAssessment.length
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "submittedDate",
                            header: "Submitted date",
                            sortable: true
                          }, {
                            body: _withCtx((slotProps) => [
                              _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(slotProps.data.submittedDate)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "requestType",
                            header: "Type",
                            sortable: true
                          }),
                          _createVNode(_component_Column, {
                            header: "Request form",
                            sortable: false
                          }, {
                            body: _withCtx(({ data }) => [
                              _createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.viewRequestForm(data)),
                                color: "primary",
                                variant: "text",
                                class: "text-decoration-underline",
                                "prepend-icon": "fa:far fa-file-alt"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" View request")
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "status",
                            header: "Status",
                            sortable: true
                          }, {
                            body: _withCtx((slotProps) => [
                              _createVNode(_component_status_chip_requested_assessment, {
                                status: slotProps.data.status
                              }, null, 8, ["status"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords"])
                    ]),
                    _: 1
                  }, 8, ["toggled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}