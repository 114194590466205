import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!

  return (_openBlock(), _createBlock(_component_formio_container, {
    formName: "educationProgramOffering",
    formData: _ctx.formData,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loaded', $event))),
    onChanged: _cache[1] || (_cache[1] = (form, event) => _ctx.$emit('changed', form, event)),
    onSubmitted: _ctx.submitOffering
  }, _createSlots({ _: 2 }, [
    (_ctx.formMode !== _ctx.OfferingFormModes.Readonly)
      ? {
          name: "actions",
          fn: _withCtx(({ submit }) => [
            _createVNode(_component_footer_buttons, {
              justify: "space-between",
              processing: _ctx.processing,
              onSecondaryClick: _ctx.cancel,
              class: "mx-0"
            }, {
              "primary-buttons": _withCtx(({ disabled }) => [
                _createElementVNode("span", null, [
                  (_ctx.formMode === _ctx.OfferingFormModes.Editable)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        disabled: disabled,
                        variant: "elevated",
                        "data-cy": "offeringValidationButton",
                        color: "primary",
                        onClick: ($event: any) => (submit({ validationOnly: true })),
                        "prepend-icon": "fa:fa fa-check"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Validate ")
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_btn, {
                    disabled: disabled,
                    class: "ml-2",
                    variant: "elevated",
                    "data-cy": "offeringSubmitButton",
                    color: "primary",
                    onClick: ($event: any) => (submit({ validationOnly: false }))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.submitLabel), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _: 2
            }, 1032, ["processing", "onSecondaryClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["formData", "onSubmitted"]))
}