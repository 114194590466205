import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_offering_form_submit = _resolveComponent("offering-form-submit")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Program detail",
        routeLocation: _ctx.routeLocation,
        subTitle: "Add offering"
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_offering_form_submit, {
        submitLabel: "Add offering now",
        formMode: _ctx.OfferingFormModes.Editable,
        locationId: _ctx.locationId,
        programId: _ctx.programId,
        onSubmit: _ctx.submit,
        onCancel: _ctx.goBack
      }, null, 8, ["formMode", "locationId", "programId", "onSubmit", "onCancel"])
    ]),
    _: 1
  }))
}