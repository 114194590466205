import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_location_edit_form = _resolveComponent("location-edit-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "All Locations",
        routeLocation: _ctx.goBackRouteParams,
        subTitle: "Edit Locations"
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_full_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_location_edit_form, {
            locationData: _ctx.initialData,
            onUpdateInstitutionLocation: _ctx.updateInstitutionLocation
          }, null, 8, ["locationData", "onUpdateInstitutionLocation"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}