import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_stepper_progress_bar = _resolveComponent("stepper-progress-bar")!
  const _component_draft = _resolveComponent("draft")!
  const _component_submitted = _resolveComponent("submitted")!
  const _component_in_progress = _resolveComponent("in-progress")!
  const _component_assessment = _resolveComponent("assessment")!
  const _component_enrolment = _resolveComponent("enrolment")!
  const _component_completed = _resolveComponent("completed")!
  const _component_cancelled = _resolveComponent("cancelled")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "p-4" }, {
    default: _withCtx(() => [
      (
        _ctx.applicationProgressDetails.applicationStatus !==
        _ctx.ApplicationStatus.Cancelled
      )
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_body_header, { title: "Track your application" }),
            _createVNode(_component_stepper_progress_bar, {
              progressBarValue: _ctx.trackerApplicationStatus,
              progressStepLabels: _ctx.applicationTrackerLabels,
              progressBarColor: _ctx.trackFillColor,
              initialStepSize: _ctx.thumbSize,
              disabled: _ctx.disabled,
              progressLabelIcon: _ctx.statusIconDetails.statusIcon,
              progressLabelIconColor: _ctx.statusIconDetails.statusType
            }, null, 8, ["progressBarValue", "progressStepLabels", "progressBarColor", "initialStepSize", "disabled", "progressLabelIcon", "progressLabelIconColor"]),
            (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.Draft
        )
              ? (_openBlock(), _createBlock(_component_draft, {
                  key: 0,
                  "are-application-actions-allowed": _ctx.areApplicationActionsAllowed,
                  onEditApplication: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editApplication')))
                }, null, 8, ["are-application-actions-allowed"]))
              : (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.Submitted
        )
                ? (_openBlock(), _createBlock(_component_submitted, { key: 1 }))
                : (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.InProgress
        )
                  ? (_openBlock(), _createBlock(_component_in_progress, {
                      key: 2,
                      "application-id": _ctx.applicationId
                    }, null, 8, ["application-id"]))
                  : (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.Assessment
        )
                    ? (_openBlock(), _createBlock(_component_assessment, {
                        key: 3,
                        assessmentTriggerType: 
          _ctx.applicationProgressDetails.assessmentTriggerType!
        
                      }, null, 8, ["assessmentTriggerType"]))
                    : (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.Enrolment
        )
                      ? (_openBlock(), _createBlock(_component_enrolment, {
                          key: 4,
                          applicationId: _ctx.applicationId
                        }, null, 8, ["applicationId"]))
                      : (
          _ctx.applicationProgressDetails.applicationStatus ===
          _ctx.ApplicationStatus.Completed
        )
                        ? (_openBlock(), _createBlock(_component_completed, {
                            key: 5,
                            "are-application-actions-allowed": _ctx.areApplicationActionsAllowed,
                            applicationId: _ctx.applicationId
                          }, null, 8, ["are-application-actions-allowed", "applicationId"]))
                        : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_cancelled, {
            key: 1,
            "application-id": _ctx.applicationId,
            "cancelled-date": _ctx.applicationProgressDetails.applicationStatusUpdatedOn
          }, null, 8, ["application-id", "cancelled-date"]))
    ]),
    _: 1
  }))
}