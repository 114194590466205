import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_application_header_title = _resolveComponent("application-header-title")!
  const _component_request_assessment = _resolveComponent("request-assessment")!
  const _component_manual_reassessment = _resolveComponent("manual-reassessment")!
  const _component_history_assessment = _resolveComponent("history-assessment")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Application history",
        routeLocation: {
          name: _ctx.AESTRoutesConst.STUDENT_APPLICATIONS,
          params: { studentId: _ctx.studentId },
        },
        subTitle: "Assessments"
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_application_header_title, { "application-id": _ctx.applicationId }, null, 8, ["application-id"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_request_assessment, {
        class: "mb-5",
        applicationId: _ctx.applicationId,
        onViewStudentAppeal: _ctx.goToStudentAppeal,
        onViewStudentApplicationOfferingChange: 
        _ctx.goToStudentApplicationOfferingChangeRequest
      ,
        onViewApplicationException: _ctx.goToApplicationException,
        onViewOfferingRequest: _ctx.goToOfferingRequest
      }, null, 8, ["applicationId", "onViewStudentAppeal", "onViewStudentApplicationOfferingChange", "onViewApplicationException", "onViewOfferingRequest"]),
      _createVNode(_component_manual_reassessment, {
        class: "mb-5",
        applicationId: _ctx.applicationId,
        onReassessmentTriggered: _ctx.reloadHistory
      }, null, 8, ["applicationId", "onReassessmentTriggered"]),
      (_openBlock(), _createBlock(_component_history_assessment, {
        class: "mb-5",
        applicationId: _ctx.applicationId,
        viewRequestTypes: _ctx.assessmentRequestTypes,
        onViewStudentAppeal: _ctx.goToStudentAppeal,
        onViewStudentApplicationOfferingChange: 
        _ctx.goToStudentApplicationOfferingChangeRequest
      ,
        onViewAssessment: _ctx.gotToViewAssessment,
        onViewOfferingRequest: _ctx.goToOfferingRequest,
        onViewApplicationException: _ctx.goToApplicationException,
        onViewScholasticStandingChange: _ctx.goToScholasticStanding,
        key: _ctx.historyKey
      }, null, 8, ["applicationId", "viewRequestTypes", "onViewStudentAppeal", "onViewStudentApplicationOfferingChange", "onViewAssessment", "onViewOfferingRequest", "onViewApplicationException", "onViewScholasticStandingChange"]))
    ]),
    _: 1
  }))
}