import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_add_manual_overaward_deduction = _resolveComponent("add-manual-overaward-deduction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header_container, { enableCardView: true }, {
      header: _withCtx(() => [
        _createVNode(_component_body_header, {
          title: "Overawards",
          subTitle: "Overaward amounts generated due to application reassessments"
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_content_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_toggle_content, {
              toggled: !_ctx.overawards.length
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  value: _ctx.overawards,
                  class: "p-m-4",
                  paginator: true,
                  rows: _ctx.pageLimit,
                  rowsPerPageOptions: _ctx.PAGINATION_LIST
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "dateAdded",
                      header: "Date added"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(slotProps.data.dateAdded
                    ? _ctx.dateOnlyLongString(slotProps.data.dateAdded)
                    : _ctx.dateOnlyLongString(slotProps.data.createdAt)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "applicationNumber",
                      header: "Application #"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.emptyStringFiller(slotProps.data.applicationNumber)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "overawardOrigin",
                      header: "Origin"
                    }),
                    _createVNode(_component_Column, {
                      field: "assessmentTriggerType",
                      header: "Type"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.emptyStringFiller(slotProps.data.assessmentTriggerType)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "awardValueCode",
                      header: "Award"
                    }),
                    _createVNode(_component_Column, {
                      field: "overawardValue",
                      header: "Overaward amount"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.formatCurrency(slotProps.data.overawardValue)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "rows", "rowsPerPageOptions"])
              ]),
              _: 1
            }, 8, ["toggled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_body_header_container, { enableCardView: true }, {
      header: _withCtx(() => [
        _createVNode(_component_body_header, {
          title: "Overaward deductions",
          subTitle: "History of money that was deducted from one or more applications to pay back what is owed"
        }, _createSlots({ _: 2 }, [
          (_ctx.allowManualOverawardDeduction)
            ? {
                name: "actions",
                fn: _withCtx(() => [
                  _createVNode(_component_check_permission_role, {
                    role: _ctx.Role.StudentAddOverawardManual
                  }, {
                    default: _withCtx(({ notAllowed }) => [
                      _createVNode(_component_v_btn, {
                        class: "ml-2 float-right",
                        color: "primary",
                        disabled: notAllowed,
                        "prepend-icon": "fa:fa fa-plus-circle",
                        onClick: _ctx.addManualOveraward
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Add manual record ")
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ]),
                    _: 1
                  }, 8, ["role"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_content_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_toggle_content, {
              toggled: !_ctx.overawardDeductions.length
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  value: _ctx.overawardDeductions,
                  class: "p-m-4",
                  paginator: true,
                  rows: _ctx.pageLimit,
                  rowsPerPageOptions: _ctx.PAGINATION_LIST
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "dateAdded",
                      header: "Date added"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(slotProps.data.dateAdded
                    ? _ctx.dateOnlyLongString(slotProps.data.dateAdded)
                    : _ctx.dateOnlyLongString(slotProps.data.createdAt)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "applicationNumber",
                      header: "Application #"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.emptyStringFiller(slotProps.data.applicationNumber)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "overawardOrigin",
                      header: "Origin"
                    }),
                    (_ctx.showAddedBy)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 0,
                          field: "addedByUser",
                          header: "Added By"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.emptyStringFiller(slotProps.data.addedByUser)), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                      field: "awardValueCode",
                      header: "Award"
                    }),
                    _createVNode(_component_Column, {
                      field: "overawardValue",
                      header: "Amount deducted"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.formatCurrency(slotProps.data.overawardValue)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "rows", "rowsPerPageOptions"])
              ]),
              _: 1
            }, 8, ["toggled"])
          ]),
          _: 1
        }),
        _createVNode(_component_add_manual_overaward_deduction, {
          ref: "addManualOverawardDeduction",
          allowedRole: _ctx.Role.StudentAddOverawardManual
        }, null, 8, ["allowedRole"])
      ]),
      _: 1
    })
  ], 64))
}