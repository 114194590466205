<template>
  <slot :notAllowed="notAllowed" :isAllowed="isAllowed"></slot>
</template>

<script lang="ts">
import { computed, PropType, defineComponent } from "vue";
import { ClientIdType, Role } from "@/types";
import { AuthService } from "@/services/AuthService";
import { useAuth } from "@/composables";

export default defineComponent({
  props: {
    role: {
      type: String as PropType<Role>,
      required: true,
    },
  },
  setup(props) {
    const { hasRole } = useAuth();

    const notAllowed = computed(() => {
      if (AuthService.shared.authClientType === ClientIdType.AEST) {
        return !hasRole(props.role);
      }
      // Non-AEST client will come here, for instance,
      // for common components used in a non-AEST client,
      // like institution/student/supporting-user,
      // this permission check doesn't matter.
      return false;
    });

    const isAllowed = !notAllowed.value;

    return {
      notAllowed,
      isAllowed,
    };
  },
});
</script>
