import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_active_application = _resolveComponent("status-chip-active-application")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { fluid: true }, {
        default: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "Applications",
            recordsCount: _ctx.applications.results?.length
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                label: "Search name or application #",
                variant: "outlined",
                modelValue: _ctx.searchCriteria,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria) = $event)),
                "data-cy": "searchCriteria",
                onKeyup: _withKeys(_ctx.searchActiveApplications, ["enter"]),
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "auto"
              }, null, 8, ["modelValue", "onKeyup"])
            ]),
            _: 1
          }, 8, ["recordsCount"]),
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.applications.results?.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DataTable, {
                    value: _ctx.applications.results,
                    lazy: true,
                    paginator: true,
                    rows: _ctx.pageLimit,
                    rowsPerPageOptions: _ctx.PAGINATION_LIST,
                    totalRecords: _ctx.applications.count,
                    onPage: _ctx.pageEvent,
                    onSort: _ctx.sortEvent
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "fullName",
                        header: "Name",
                        sortable: true
                      }),
                      _createVNode(_component_Column, {
                        field: "studyStartPeriod",
                        header: "Study dates"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyStartPeriod)) + " - " + _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyEndPeriod)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "applicationNumber",
                        header: "Application #",
                        sortable: true
                      }),
                      _createVNode(_component_Column, {
                        field: "applicationStatus",
                        header: "Status"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_status_chip_active_application, {
                            status: slotProps.data.applicationScholasticStandingStatus
                          }, null, 8, ["status"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, { header: "Action" }, {
                        body: _withCtx((slotProps) => [
                          (
                    slotProps.data.applicationScholasticStandingStatus ===
                    _ctx.ApplicationScholasticStandingStatus.Available
                  )
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                color: "primary",
                                onClick: ($event: any) => (_ctx.goToViewApplication(slotProps.data.applicationId))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Report a change")
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (
                    slotProps.data.applicationScholasticStandingStatus ===
                    _ctx.ApplicationScholasticStandingStatus.Completed
                  )
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 1,
                                color: "primary",
                                onClick: ($event: any) => (
                    _ctx.goToViewScholasticStanding(
                      slotProps.data.scholasticStandingId,
                    )
                  )
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("View")
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords", "onPage", "onSort"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}