import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!

  return (_openBlock(), _createBlock(_component_modal_dialog_base, {
    title: "Confirm Extend Time",
    dialogType: "warning",
    showDialog: _ctx.showDialog
  }, {
    content: _withCtx(() => [
      _createVNode(_component_v_container, { class: "p-component text-dark" }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _createTextVNode(" You are about to be logged off, do you wish to extend your time? "),
            _createVNode(_component_v_btn, {
              class: "ml-2 text-white",
              color: "warning",
              icon: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.countdown), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_footer_buttons, {
        primaryLabel: "Yes",
        secondaryLabel: "No",
        onPrimaryClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resolvePromise(true))),
        onSecondaryClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resolvePromise(false)))
      })
    ]),
    _: 1
  }, 8, ["showDialog"]))
}