import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_icon, {
      icon: _ctx.icon,
      color: _ctx.status,
      size: "20"
    }, null, 8, ["icon", "color"]),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}