import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_scholastic_standing_form = _resolveComponent("scholastic-standing-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Report a change",
        routeLocation: _ctx.goBackRouteParams,
        subTitle: "View Application"
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_scholastic_standing_form, {
        initialData: _ctx.initialData,
        readOnly: false,
        onSubmit: _ctx.submit,
        processing: _ctx.processing,
        onCancel: _ctx.goBack
      }, null, 8, ["initialData", "onSubmit", "processing", "onCancel"])
    ]),
    _: 1
  }))
}