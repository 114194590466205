import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_header = _resolveComponent("dynamic-header")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { "no-gutters": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "auto",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dynamic_header, {
              title: _ctx.fullTitleMessage,
              level: _ctx.titleHeaderLevel,
              class: "category-header-large color-blue"
            }, null, 8, ["title", "level"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "auto",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "status-chip")
          ]),
          _: 3
        }),
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "actions")
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createVNode(_component_v_row, {
      "no-gutters": "",
      class: "mb-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "subtitle", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ], 64))
}