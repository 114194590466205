import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-5" }
const _hoisted_2 = { class: "ml-5 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _renderSlot(_ctx.$slots, "header"),
      _renderSlot(_ctx.$slots, "sub-header")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "details-header")
    ]),
    _renderSlot(_ctx.$slots, "alerts"),
    _createVNode(_component_v_container, { fluid: _ctx.fullWidth }, {
      default: _withCtx(() => [
        (_ctx.layoutTemplate === _ctx.LayoutTemplates.CenteredCard)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  class: _normalizeClass(["mt-4 p-4 w-100", _ctx.widthClass])
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _: 3
                }, 8, ["class"])
              ]),
              _: 3
            }))
          : (_ctx.layoutTemplate === _ctx.LayoutTemplates.Centered)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 1,
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["mt-4 w-100", _ctx.widthClass])
                  }, [
                    _renderSlot(_ctx.$slots, "default")
                  ], 2)
                ]),
                _: 3
              }))
            : (_ctx.layoutTemplate === _ctx.LayoutTemplates.CenteredTab)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 2,
                  justify: "center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["w-100", _ctx.widthClass])
                    }, [
                      _renderSlot(_ctx.$slots, "tab-header"),
                      _renderSlot(_ctx.$slots, "default")
                    ], 2)
                  ]),
                  _: 3
                }))
              : (_ctx.layoutTemplate === _ctx.LayoutTemplates.CenteredCardTab)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 3,
                    justify: "center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["mt-4 p-4 w-100", _ctx.widthClass])
                      }, [
                        _renderSlot(_ctx.$slots, "tab-header"),
                        _createVNode(_component_v_card, { class: "mt-4 p-4" }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                          ]),
                          _: 3
                        })
                      ], 2)
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["fluid"])
  ]))
}