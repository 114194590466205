import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-1 black-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    color: _ctx.chipColor,
    variant: "outlined"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        color: _ctx.iconColor,
        icon: "fa:fa fa-circle",
        size: "15"
      }, null, 8, ["color"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label ?? _ctx.status), 1)
    ]),
    _: 1
  }, 8, ["color"]))
}