import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "label-bold-normal" }
const _hoisted_2 = { class: "label-value-normal" }
const _hoisted_3 = {
  key: 0,
  class: "mx-1 brand-gray-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerEntries, ([headerKey, headerValue], index) => {
    return (_openBlock(), _createElementBlock("span", { key: headerKey }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(headerKey) + ": ", 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(headerValue), 1),
      (index < _ctx.headerEntries.length - 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "|"))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}