import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_notes = _resolveComponent("notes")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, { title: "Notes" }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn_toggle, {
            modelValue: _ctx.toggleNotes,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toggleNotes) = $event)),
            mandatory: "",
            class: "float-right btn-toggle",
            "selected-class": "selected-btn-toggle"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                rounded: "xl",
                color: "primary",
                "data-cy": "allNotesButton",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterNotes())),
                value: "allNotes"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("All Notes")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.StudentNoteType, (item) => {
                return (_openBlock(), _createBlock(_component_v_btn, {
                  rounded: "xl",
                  key: item,
                  color: "primary",
                  value: item,
                  "data-cy": item,
                  class: "ml-2",
                  onClick: ($event: any) => (_ctx.filterNotes(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["value", "data-cy", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_notes, {
        title: "Past Notes",
        entityType: _ctx.NoteEntityType.Student,
        notes: _ctx.notes,
        onSubmitData: _ctx.addNote,
        allowedRole: _ctx.Role.StudentCreateNote,
        allowAddingNotes: _ctx.allowAddingNotes
      }, null, 8, ["entityType", "notes", "onSubmitData", "allowedRole", "allowAddingNotes"])
    ]),
    _: 1
  }))
}