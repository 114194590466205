import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-bold-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, { enableCardView: true }, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, { title: "Scholastic Standing Limited History" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, " Number of Lifetime Weeks of Unsuccessful Completion: " + _toDisplayString(_ctx.scholasticStandingSummary.lifetimeUnsuccessfulCompletionWeeks), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}