import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_header_title_value = _resolveComponent("header-title-value")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessments",
        subTitle: "View request(s)",
        routeLocation: _ctx.backRouteLocation
      }, null, 8, ["routeLocation"])
    ]),
    "sub-header": _withCtx(() => [
      _createVNode(_component_header_title_value, {
        title: "Submitted date",
        value: _ctx.submittedDate
      }, null, 8, ["value"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "studentExceptions",
        formData: _ctx.applicationExceptions,
        readOnly: _ctx.readOnlyForm,
        onSubmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitted', $event)))
      }, _createSlots({ _: 2 }, [
        (!_ctx.readOnly)
          ? {
              name: "actions",
              fn: _withCtx(({ submit }) => [
                _createVNode(_component_check_permission_role, {
                  role: _ctx.Role.StudentApproveDeclineExceptions
                }, {
                  default: _withCtx(({ notAllowed }) => [
                    _createVNode(_component_footer_buttons, {
                      processing: _ctx.processing,
                      primaryLabel: "Complete student request",
                      disablePrimaryButton: notAllowed,
                      onPrimaryClick: submit,
                      onSecondaryClick: _ctx.gotToAssessmentsSummary
                    }, null, 8, ["processing", "disablePrimaryButton", "onPrimaryClick", "onSecondaryClick"])
                  ]),
                  _: 2
                }, 1032, ["role"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["formData", "readOnly"])
    ]),
    _: 1
  }))
}