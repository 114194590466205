import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_application_exceptions_approval = _resolveComponent("application-exceptions-approval")!

  return (_openBlock(), _createBlock(_component_application_exceptions_approval, {
    studentId: _ctx.studentId,
    exceptionId: _ctx.exceptionId,
    backRouteLocation: _ctx.assessmentsSummaryRoute,
    readOnlyForm: true,
    "application-id": _ctx.applicationId
  }, null, 8, ["studentId", "exceptionId", "backRouteLocation", "application-id"]))
}