import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_c_o_e = _resolveComponent("status-chip-c-o-e")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { fluid: true }, {
        default: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: _ctx.header,
            recordsCount: _ctx.disbursements.results?.length
          }, {
            subtitle: _withCtx(() => [
              _renderSlot(_ctx.$slots, "coeSummarySubtitle", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.coeSummarySubtitle), 1)
              ])
            ]),
            actions: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                label: "Search Name",
                variant: "outlined",
                modelValue: _ctx.searchCriteria,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria) = $event)),
                "data-cy": "searchCriteria",
                onKeyup: _withKeys(_ctx.searchCOE, ["enter"]),
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "auto"
              }, null, 8, ["modelValue", "onKeyup"])
            ]),
            _: 3
          }, 8, ["title", "recordsCount"]),
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.disbursements.count
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DataTable, {
                    value: _ctx.disbursements.results,
                    lazy: true,
                    paginator: true,
                    rows: _ctx.pageLimit,
                    rowsPerPageOptions: _ctx.rowsPerPageOptions,
                    totalRecords: _ctx.disbursements.count,
                    onPage: _ctx.pageEvent,
                    onSort: _ctx.sortEvent
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "fullName",
                        header: "Name",
                        sortable: true
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("span", null, _toDisplayString(slotProps.data.fullName), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "studyStartPeriod",
                        header: "Study dates"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyStartPeriod)) + " - " + _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyEndPeriod)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "applicationNumber",
                        header: "Application #"
                      }),
                      _createVNode(_component_Column, {
                        field: "disbursementDate",
                        header: "Disbursement date"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.disbursementDate)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "coeStatus",
                        header: "Status",
                        sortable: true
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_status_chip_c_o_e, {
                            status: slotProps.data.coeStatus
                          }, null, 8, ["status"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "applicationId",
                        header: "Action"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: ($event: any) => (
                    _ctx.goToViewApplication(slotProps.data.disbursementScheduleId)
                  )
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("View")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords", "onPage", "onSort"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}