import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createBlock(_component_banner, {
    class: "mb-2",
    type: _ctx.BannerTypes.Warning,
    header: _ctx.bannerText
  }, null, 8, ["type", "header"]))
}