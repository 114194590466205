import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_manage_program_and_offering_summary = _resolveComponent("manage-program-and-offering-summary")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, {
    "full-width": true,
    "layout-template": "centered"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Programs",
        routeLocation: {
          name: _ctx.InstitutionRoutesConst.LOCATION_PROGRAMS,
          params: { locationId: _ctx.locationId },
        },
        subTitle: "Program Detail",
        "data-cy": "programDetailHeader"
      }, null, 8, ["routeLocation"])
    ]),
    "details-header": _withCtx(() => [
      _createVNode(_component_program_offering_detail_header, {
        headerDetails: {
          ..._ctx.educationProgram,
          status: _ctx.educationProgram.programStatus,
        }
      }, null, 8, ["headerDetails"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_manage_program_and_offering_summary, {
        programId: _ctx.programId,
        locationId: _ctx.locationId,
        educationProgram: _ctx.educationProgram,
        onProgramDataUpdated: _ctx.programDataUpdated
      }, null, 8, ["programId", "locationId", "educationProgram", "onProgramDataUpdated"])
    ]),
    _: 1
  }))
}