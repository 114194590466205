import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_search_students = _resolveComponent("search-students")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, { title: "Search Students" }, {
            subtitle: _withCtx(() => [
              _createTextVNode("Lookup students by entering their information below.")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_search_students, { onGoToStudentView: _ctx.goToStudentView }, null, 8, ["onGoToStudentView"])
    ]),
    _: 1
  }))
}