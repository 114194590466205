import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio = _resolveComponent("formio")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_formio, {
      formName: _ctx.formName,
      data: _ctx.formData,
      readOnly: _ctx.readOnly,
      onLoaded: _ctx.formLoaded,
      onCustomEvent: _ctx.formCustomEvent,
      onRender: _ctx.formRender,
      onChanged: _ctx.formChanged
    }, null, 8, ["formName", "data", "readOnly", "onLoaded", "onCustomEvent", "onRender", "onChanged"]),
    _renderSlot(_ctx.$slots, "actions", { submit: _ctx.submit })
  ], 64))
}