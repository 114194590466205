import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_application_offering_change_details_header = _resolveComponent("application-offering-change-details-header")!
  const _component_banner = _resolveComponent("banner")!
  const _component_student_application_offering_change_details = _resolveComponent("student-application-offering-change-details")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_offering_form = _resolveComponent("offering-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_assess_application_offering_change_request_modal = _resolveComponent("assess-application-offering-change-request-modal")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered-card-tab" }, _createSlots({
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessments",
        routeLocation: _ctx.goBackRouteParams,
        subTitle: "View Request"
      }, _createSlots({ _: 2 }, [
        (
            _ctx.applicationOfferingChangeRequestDetails.status ===
            _ctx.ApplicationOfferingChangeRequestStatus.InProgressWithSABC
          )
          ? {
              name: "buttons",
              fn: _withCtx(() => [
                _createVNode(_component_check_permission_role, {
                  role: 
              _ctx.Role.InstitutionApproveDeclineApplicationOfferingChangeRequest
            
                }, {
                  default: _withCtx(({ notAllowed }) => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      variant: "outlined",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.assessApplicationOfferingChangeRequest(
                    _ctx.ApplicationOfferingChangeRequestStatus.DeclinedBySABC,
                  )
                )),
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Decline reassessment")
                      ]),
                      _: 2
                    }, 1032, ["disabled"]),
                    _createVNode(_component_v_btn, {
                      class: "ml-2",
                      color: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.assessApplicationOfferingChangeRequest(
                    _ctx.ApplicationOfferingChangeRequestStatus.Approved,
                  )
                )),
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Approve reassessment")
                      ]),
                      _: 2
                    }, 1032, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["role"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["routeLocation"]),
      _createVNode(_component_application_offering_change_details_header, { headerDetails: _ctx.headerDetails }, null, 8, ["headerDetails"])
    ]),
    "tab-header": _withCtx(() => [
      _createVNode(_component_student_application_offering_change_details, {
        class: "mb-6",
        applicationOfferingChangeDetails: _ctx.studentApplicationOfferingDetails
      }, null, 8, ["applicationOfferingChangeDetails"]),
      _createVNode(_component_v_tabs, {
        stacked: "",
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, {
            value: "requested-change",
            ripple: false
          }, {
            default: _withCtx(() => [
              _createTextVNode("Requested Change")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, {
            value: "active-offering",
            ripple: false
          }, {
            default: _withCtx(() => [
              _createTextVNode("Active Offering")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_window_item, { value: "requested-change" }, {
            default: _withCtx(() => [
              _createVNode(_component_offering_form, {
                offeringId: 
            _ctx.applicationOfferingChangeRequestDetails.requestedOfferingId
          
              }, null, 8, ["offeringId"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_window_item, { value: "active-offering" }, {
            default: _withCtx(() => [
              _createVNode(_component_offering_form, {
                offeringId: _ctx.applicationOfferingChangeRequestDetails.activeOfferingId
              }, null, 8, ["offeringId"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_assess_application_offering_change_request_modal, { ref: "assessApplicationOfferingChangeRequestModal" }, null, 512)
    ]),
    _: 2
  }, [
    (
        _ctx.applicationOfferingChangeRequestDetails.status ===
        _ctx.ApplicationOfferingChangeRequestStatus.InProgressWithStudent
      )
      ? {
          name: "alerts",
          fn: _withCtx(() => [
            _createVNode(_component_banner, {
              class: "mb-2",
              type: _ctx.BannerTypes.Warning,
              header: "This request is still pending with the student",
              summary: "The option to approve or decline for reassessment will be available once the student gives permission for the change. Please follow back shortly or contact the student."
            }, null, 8, ["type"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}