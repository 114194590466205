import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "category-header-medium mb-3 label" }
const _hoisted_2 = { class: "label-value-normal" }
const _hoisted_3 = { class: "my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    variant: "tonal",
    class: _normalizeClass(["application-tracker-alert mt-3", _ctx.backgroundColor]),
    color: _ctx.backgroundColor
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_v_icon, {
        class: "mt-1",
        icon: _ctx.icon,
        size: 20,
        color: _ctx.iconColor
      }, null, 8, ["icon", "color"])
    ]),
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.label), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content", {}, () => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "auto" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                "offset-md": "5",
                class: "mt-n8"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "image")
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "actions")
      ])
    ]),
    _: 3
  }, 8, ["class", "color"]))
}