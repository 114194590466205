import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_formio = _resolveComponent("formio")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Search for application",
        "title-header-level": "1",
        subTitle: "To provide your supporting information, please search for the application\n      by entering the requested information below. All fields are mandatory and\n      must match exactly with the information provided on the student\n      application."
      }),
      _createVNode(_component_v_form, { ref: "searchApplicationsForm" }, {
        default: _withCtx(() => [
          _createVNode(_component_content_group, { class: "mb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        label: "Application number",
                        variant: "outlined",
                        modelValue: _ctx.applicationNumber,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.applicationNumber) = $event)),
                        "data-cy": "applicationNumber",
                        rules: [
                (v) => _ctx.checkNullOrEmptyRule(v, 'Number'),
                (v) => _ctx.checkOnlyDigitsRule(v, 'Number'),
              ],
                        "hide-details": "auto"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        label: "Student's last name",
                        variant: "outlined",
                        modelValue: _ctx.studentsLastName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.studentsLastName) = $event)),
                        "data-cy": "studentsLastName",
                        rules: [(v) => _ctx.checkNullOrEmptyRule(v, 'Last name')],
                        "hide-details": "auto"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        label: "Student's date of birth",
                        variant: "outlined",
                        modelValue: _ctx.studentsDateOfBirth,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.studentsDateOfBirth) = $event)),
                        "data-cy": "studentsDateOfBirth",
                        type: "date",
                        rules: [(v) => _ctx.checkNullOrEmptyRule(v, 'Date of birth')],
                        "hide-details": "auto"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.applicationSearch
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Search")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      (_ctx.formName)
        ? (_openBlock(), _createBlock(_component_formio, {
            key: 0,
            formName: _ctx.formName,
            data: _ctx.initialData,
            readOnly: _ctx.submitting,
            onSubmitted: _ctx.submitted,
            onLoaded: _ctx.formLoaded
          }, null, 8, ["formName", "data", "readOnly", "onSubmitted", "onLoaded"]))
        : _createCommentVNode("", true),
      (_ctx.showNav)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_v_btn, {
                    color: "primary",
                    variant: "outlined",
                    "data-cy": "previousSection",
                    onClick: _ctx.wizardGoPrevious
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Back")
                    ]),
                    _: 1
                  }, 8, ["onClick"]), [
                    [_vShow, !_ctx.isFirstPage]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_v_btn, {
                    class: "float-right",
                    color: "primary",
                    onClick: _ctx.wizardGoNext
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Next step")
                    ]),
                    _: 1
                  }, 8, ["onClick"]), [
                    [_vShow, !_ctx.isLastPage]
                  ]),
                  _withDirectives(_createVNode(_component_v_btn, {
                    class: "float-right",
                    disabled: !_ctx.isLastPage || _ctx.submitting,
                    color: "primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.wizardSubmit())),
                    loading: _ctx.submitting
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.submitting ? "Submitting..." : "Submit form"), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"]), [
                    [_vShow, !_ctx.isFirstPage]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}