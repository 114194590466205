import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_application_status_tracker_banner = _resolveComponent("application-status-tracker-banner")!

  return (_openBlock(), _createBlock(_component_application_status_tracker_banner, {
    label: "You have a new assessment due to another application",
    icon: "fa:fas fa-check-circle",
    "icon-color": "success",
    "background-color": "success-bg"
  }, {
    content: _withCtx(() => [
      _createTextVNode(" An application in this program has changed and due to limits within the program year, this application may have been affected. Please review the assessment for any changes. ")
    ]),
    _: 1
  }))
}