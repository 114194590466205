import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_summary = _resolveComponent("error-summary")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "addCASSupplierForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        title: "Update supplier/site information",
        "sub-title": "Enter new supplier number and site number for this student to maintain\n          consistency with CAS validations.",
        showDialog: _ctx.showDialog
      }, {
        content: _withCtx(() => [
          _createVNode(_component_error_summary, {
            errors: _ctx.addCASSupplierForm.errors
          }, null, 8, ["errors"]),
          _createVNode(_component_v_text_field, {
            "hide-details": "auto",
            label: "Supplier number",
            modelValue: _ctx.formModel.supplierNumber,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.supplierNumber) = $event)),
            variant: "outlined",
            rules: [_ctx.supplierNumberValidationRule],
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formatSupplierNumber($event))),
            required: "",
            class: "mb-4"
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_text_field, {
            "hide-details": "auto",
            label: "Site code",
            modelValue: _ctx.formModel.supplierSiteCode,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.supplierSiteCode) = $event)),
            variant: "outlined",
            rules: [_ctx.supplierSiteCodeValidationRule],
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.formatSupplierSiteCode($event))),
            required: ""
          }, null, 8, ["modelValue", "rules"])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_footer_buttons, {
            processing: _ctx.loading,
            primaryLabel: "Update info",
            onSecondaryClick: _ctx.cancel,
            onPrimaryClick: _ctx.submit
          }, null, 8, ["processing", "onSecondaryClick", "onPrimaryClick"])
        ]),
        _: 1
      }, 8, ["showDialog"])
    ]),
    _: 1
  }, 512))
}