import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_scholastic_standing_form = _resolveComponent("scholastic-standing-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Report a change",
        subTitle: "View Application",
        routeLocation: _ctx.goBackRouteParams
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_scholastic_standing_form, {
        initialData: _ctx.initialData,
        readOnly: true
      }, null, 8, ["initialData"])
    ]),
    _: 1
  }))
}