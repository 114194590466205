import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header-title"
}
const _hoisted_2 = {
  key: 1,
  class: "header-title"
}
const _hoisted_3 = { class: "float-left" }
const _hoisted_4 = { class: "header-sub-title" }
const _hoisted_5 = { class: "float-left" }
const _hoisted_6 = { class: "float-right header-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    align: "end",
    class: "mb-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            (_ctx.routeLocation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
                  }, [
                    _createVNode(_component_v_icon, {
                      icon: "fa:fa fa-arrow-left",
                      size: "25"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
          ]),
          _createElementVNode("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "subTitle", {}, () => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subTitle), 1)
            ])
          ]),
          _createElementVNode("span", _hoisted_5, [
            _renderSlot(_ctx.$slots, "sub-title-details")
          ])
        ]),
        _: 3
      }),
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "buttons")
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}