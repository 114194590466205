import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio = _resolveComponent("formio")!
  const _component_appeal_requests_form = _resolveComponent("appeal-requests-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_appeal_requests_form, {
      studentAppealRequests: _ctx.studentAppealRequests,
      readOnly: true
    }, _createSlots({ _: 2 }, [
      (_ctx.showApprovalDetails)
        ? {
            name: "approval-form",
            fn: _withCtx(({ approval }) => [
              _createVNode(_component_formio, {
                formName: "staffapprovalappeal",
                data: approval,
                readOnly: _ctx.readOnly,
                scoped: true,
                onLoaded: _ctx.approvalFormLoaded
              }, null, 8, ["data", "readOnly", "onLoaded"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["studentAppealRequests"]),
    _renderSlot(_ctx.$slots, "approval-actions", { submit: _ctx.submit })
  ], 64))
}