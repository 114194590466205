import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_reports = _resolveComponent("reports", true)!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { class: "overflow-visible" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Ministry",
        subTitle: "Reports"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_reports, { "report-list": _ctx.MINISTRY_REPORTS }, null, 8, ["report-list"])
    ]),
    _: 1
  }))
}