import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-cy": "designationSubmittedDate" }
const _hoisted_2 = { "data-cy": "designationStartDate" }
const _hoisted_3 = { "data-cy": "designationEndDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_designation = _resolveComponent("status-chip-designation")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!

  return (_openBlock(), _createBlock(_component_content_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_toggle_content, {
        toggled: !_ctx.designations.length,
        message: _ctx.toggleMessage
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DataTable, {
            value: _ctx.designations,
            paginator: true,
            rows: _ctx.DEFAULT_PAGE_LIMIT,
            rowsPerPageOptions: _ctx.PAGINATION_LIST,
            totalRecords: _ctx.designations.length,
            "data-cy": "designationsList"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, { header: "Date submitted" }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.submittedDate)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { header: "Start date" }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.startDate)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { header: "Expiry date" }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.endDate)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { header: "Status" }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_status_chip_designation, {
                    status: slotProps.data.designationStatus,
                    "data-cy": "designationStatus"
                  }, null, 8, ["status"])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { header: "Action" }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    "data-cy": "viewDesignation",
                    onClick: ($event: any) => (_ctx.goToViewDesignation(slotProps.data.designationId))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" View ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords"])
        ]),
        _: 1
      }, 8, ["toggled", "message"])
    ]),
    _: 1
  }))
}