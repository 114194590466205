import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_institution_user_management = _resolveComponent("institution-user-management")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "addUserForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        showDialog: _ctx.showDialog,
        "data-cy": "addNewUserModal",
        title: "Add new user"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_institution_user_management, {
            ref: "institutionUserManagement",
            errors: _ctx.addUserForm.errors,
            initialData: _ctx.initialData
          }, {
            "user-name": _withCtx(({ formModel }) => [
              (_ctx.hasBusinessGuid && _ctx.canSearchBCeIDUsers)
                ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                    key: 0,
                    "hide-details": "auto",
                    modelValue: formModel.selectedBCeIDUser,
                    "onUpdate:modelValue": ($event: any) => ((formModel.selectedBCeIDUser) = $event),
                    items: _ctx.bceidUsers,
                    class: "mr-3 bceid-input",
                    density: "compact",
                    variant: "outlined",
                    label: "Business BCeID user Id",
                    "data-cy": "inputUserId",
                    rules: [(v) => !!v || 'Business BCeID user Id is required.']
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "rules"]))
                : (_openBlock(), _createBlock(_component_v_text_field, {
                    key: 1,
                    "hide-details": "auto",
                    modelValue: formModel.selectedBCeIDUser,
                    "onUpdate:modelValue": ($event: any) => ((formModel.selectedBCeIDUser) = $event),
                    modelModifiers: { trim: true },
                    class: "mr-3 bceid-input",
                    density: "compact",
                    variant: "outlined",
                    label: _ctx.userNameLabel,
                    rules: [(v) => !!v || 'BCeID user Id is required']
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]))
            ]),
            _: 1
          }, 8, ["errors", "initialData"])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.InstitutionAddNewUser
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_footer_buttons, {
                processing: _ctx.processing,
                primaryLabel: "Add user now",
                "data-cy": "addNewUserModal",
                onPrimaryClick: _ctx.submit,
                onSecondaryClick: _ctx.cancel,
                disablePrimaryButton: notAllowed
              }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick", "disablePrimaryButton"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["showDialog"])
    ]),
    _: 1
  }, 512))
}