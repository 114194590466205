import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider_inset_opaque = _resolveComponent("v-divider-inset-opaque")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_approval_disabled_banner = _resolveComponent("approval-disabled-banner")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_approve_c_o_e = _resolveComponent("approve-c-o-e")!
  const _component_deny_c_o_e = _resolveComponent("deny-c-o-e")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Confirm enrolment",
        routeLocation: {
          name: _ctx.InstitutionRoutesConst.COE_SUMMARY,
          params: {
            locationId: _ctx.locationId,
          },
        },
        subTitle: "View Financial Aid Application"
      }, {
        buttons: _withCtx(() => [
          (_ctx.initialData.applicationCOEStatus === _ctx.COEStatus.required)
            ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ color: "primary" }, props, { "prepend-icon": "fa:fa fa-chevron-circle-down" }), {
                    default: _withCtx(() => [
                      _createTextVNode(" Application actions ")
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, {
                    "active-class": "active-list-item",
                    density: "compact",
                    "bg-color": "default",
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          _createVNode(_component_v_list_item, {
                            value: index,
                            onClick: item.command
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["value", "onClick"]),
                          (index < _ctx.items.length - 1)
                            ? (_openBlock(), _createBlock(_component_v_divider_inset_opaque, { key: index }))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["routeLocation"])
    ]),
    alerts: _withCtx(() => [
      (_ctx.initialData.applicationCOEStatus === _ctx.COEStatus.required)
        ? (_openBlock(), _createBlock(_component_approval_disabled_banner, {
            key: 0,
            coeApprovalPeriodStatus: _ctx.initialData.coeApprovalPeriodStatus
          }, null, 8, ["coeApprovalPeriodStatus"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "confirmsStudentEnrollment",
        formData: _ctx.initialData
      }, null, 8, ["formData"]),
      _createVNode(_component_approve_c_o_e, {
        ref: "confirmCOEModal",
        maxTuitionRemittance: _ctx.initialData.maxTuitionRemittanceAllowed,
        hasOverawards: _ctx.initialData.hasOverawardBalance
      }, null, 8, ["maxTuitionRemittance", "hasOverawards"]),
      _createVNode(_component_deny_c_o_e, { ref: "denyCOEModal" }, null, 512)
    ]),
    _: 1
  }))
}