import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_announcement_banner = _resolveComponent("announcement-banner")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_student_page_container = _resolveComponent("student-page-container")!

  return (_openBlock(), _createBlock(_component_student_page_container, { "layout-template": "centered" }, {
    alerts: _withCtx(() => [
      _createVNode(_component_announcement_banner, { dashboard: "student-dashboard" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "studentWelcomePage",
        formData: _ctx.studentDetails,
        onCustomEvent: _ctx.goToStudentApplication
      }, null, 8, ["formData", "onCustomEvent"])
    ]),
    _: 1
  }))
}