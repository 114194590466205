import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_manage_program_and_offering_summary = _resolveComponent("manage-program-and-offering-summary")!
  const _component_ApproveProgramModal = _resolveComponent("ApproveProgramModal")!
  const _component_DeclineProgramModal = _resolveComponent("DeclineProgramModal")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Back all programs",
        routeLocation: {
        name: _ctx.AESTRoutesConst.INSTITUTION_PROGRAMS,
        params: { institutionId: _ctx.institutionId },
      },
        subTitle: "View program"
      }, {
        buttons: _withCtx(() => [
          (_ctx.isPendingProgram)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "p-0 m-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_check_permission_role, {
                    role: _ctx.Role.InstitutionApproveDeclineProgram
                  }, {
                    default: _withCtx(({ notAllowed }) => [
                      _createVNode(_component_v_btn, {
                        variant: "outlined",
                        color: "primary",
                        class: "mr-2",
                        onClick: _ctx.declineProgram,
                        disabled: notAllowed
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Decline")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"]),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.approveProgram,
                        disabled: notAllowed
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Approve program")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 1
                  }, 8, ["role"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["routeLocation"]),
      _createVNode(_component_program_offering_detail_header, {
        class: "m-4",
        headerDetails: {
        ..._ctx.educationProgram,
        status: _ctx.educationProgram.programStatus,
      }
      }, null, 8, ["headerDetails"]),
      _createVNode(_component_manage_program_and_offering_summary, {
        programId: _ctx.programId,
        locationId: _ctx.locationId,
        educationProgram: _ctx.educationProgram,
        institutionId: _ctx.institutionId,
        onProgramDataUpdated: _ctx.programDataUpdated
      }, null, 8, ["programId", "locationId", "educationProgram", "institutionId", "onProgramDataUpdated"]),
      _createVNode(_component_ApproveProgramModal, { ref: "approveProgramModal" }, null, 512),
      _createVNode(_component_DeclineProgramModal, { ref: "declineProgramModal" }, null, 512)
    ]),
    _: 1
  }))
}