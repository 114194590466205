import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-bold-normal" }
const _hoisted_2 = { class: "label-value-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.iconLabel)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 0,
          class: "mr-2",
          icon: _ctx.iconLabel,
          size: "20",
          color: _ctx.iconColor
        }, null, 8, ["icon", "color"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title) + ": ", 1),
    _renderSlot(_ctx.$slots, "value", {}, () => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.value), 1)
    ])
  ]))
}