import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trigger_reassessment_modal = _resolveComponent("trigger-reassessment-modal")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, { enableCardView: true }, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Trigger Reassessment Manually",
        subTitle: "Manual system trigger to re-perform assessment using existing application inputs. This should be used as a means of triggering the assessment or other downstream actions (COE requests, eCert requests) without requiring the student to edit and resubmit their application."
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_trigger_reassessment_modal, { ref: "reassessmentModal" }, null, 512),
      _createVNode(_component_content_group, { class: "mt-4 pb-16" }, {
        default: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.AESTManualTriggerReassessment
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_v_btn, {
                class: "ml-2 float-right",
                color: "primary",
                "prepend-icon": "fa:fa fa-refresh",
                disabled: notAllowed || _ctx.openModalButtonDisabled,
                onClick: _ctx.openTriggerReassessmentModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Trigger reassessment ")
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}