import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_formio = _resolveComponent("formio")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header, { title: "Export reports" }),
    _createVNode(_component_formio, {
      formName: "exportfinancialreports",
      onLoaded: _ctx.formLoaded,
      onChanged: _ctx.formChanged,
      onSubmitted: _ctx.exportReport
    }, null, 8, ["onLoaded", "onChanged", "onSubmitted"]),
    _createVNode(_component_v_row, { class: "justify-center m-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_check_permission_role, {
          role: _ctx.Role.AESTReports
        }, {
          default: _withCtx(({ notAllowed }) => [
            _createVNode(_component_v_btn, {
              color: "primary",
              onClick: _ctx.submitForm,
              disabled: notAllowed,
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Export CSV file")
              ]),
              _: 2
            }, 1032, ["onClick", "disabled", "loading"])
          ]),
          _: 1
        }, 8, ["role"])
      ]),
      _: 1
    })
  ], 64))
}