import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_formio_container = _resolveComponent("formio-container")!

  return (_openBlock(), _createBlock(_component_formio_container, {
    formName: "institutionProfile",
    formData: _ctx.profileData,
    onLoaded: _ctx.formLoaded,
    onSubmitted: _ctx.submitInstitutionProfile
  }, {
    actions: _withCtx(({ submit }) => [
      _createVNode(_component_check_permission_role, { role: _ctx.allowedRole }, {
        default: _withCtx(({ notAllowed }) => [
          _createVNode(_component_footer_buttons, {
            processing: _ctx.processing,
            primaryLabel: _ctx.submitLabel,
            onPrimaryClick: submit,
            showSecondaryButton: false,
            disablePrimaryButton: notAllowed
          }, null, 8, ["processing", "primaryLabel", "onPrimaryClick", "disablePrimaryButton"])
        ]),
        _: 2
      }, 1032, ["role"])
    ]),
    _: 1
  }, 8, ["formData", "onLoaded", "onSubmitted"]))
}