import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_detail_header = _resolveComponent("detail-header")!
  const _component_assessment_award = _resolveComponent("assessment-award", true)!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessments",
        subTitle: "View Assessment",
        routeLocation: _ctx.routeLocation
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_detail_header, { headerMap: _ctx.headerMap }, null, 8, ["headerMap"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_assessment_award, {
        "assessment-award-data": _ctx.assessmentAwardData,
        "notice-of-assessment-route": _ctx.noticeOfAssessmentRoute
      }, null, 8, ["assessment-award-data", "notice-of-assessment-route"])
    ]),
    _: 1
  }))
}