import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.enableCardView)
      ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "header"),
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header"),
          _renderSlot(_ctx.$slots, "default")
        ]))
  ]))
}