import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_summary = _resolveComponent("error-summary")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "addRestrictionForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        title: "Add new restriction",
        showDialog: _ctx.showDialog,
        "min-width": "730"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_error_summary, {
            errors: _ctx.addRestrictionForm.errors
          }, null, 8, ["errors"]),
          _createVNode(_component_v_select, {
            class: "mt-4",
            label: "Category",
            density: "compact",
            items: _ctx.restrictionCategories,
            modelValue: _ctx.selectedCategory,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCategory) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.categoryReasonItems()))
            ],
            variant: "outlined",
            rules: [(v) => !!v || 'Category is required.']
          }, null, 8, ["items", "modelValue", "rules"]),
          _createVNode(_component_v_select, {
            label: "Reason",
            density: "compact",
            items: _ctx.restrictionReasons,
            modelValue: _ctx.formModel.restrictionId,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.restrictionId) = $event)),
            variant: "outlined",
            rules: [(v) => !!v || 'Reason is required.']
          }, null, 8, ["items", "modelValue", "rules"]),
          _createVNode(_component_v_textarea, {
            label: "Notes",
            placeholder: "Long text...",
            modelValue: _ctx.formModel.noteDescription,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.noteDescription) = $event)),
            variant: "outlined",
            rules: [_ctx.checkNotesLengthRule]
          }, null, 8, ["modelValue", "rules"])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_check_permission_role, { role: _ctx.allowedRole }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_footer_buttons, {
                processing: _ctx.processing,
                primaryLabel: "Add Restriction",
                onPrimaryClick: _ctx.submit,
                onSecondaryClick: _ctx.cancel,
                disablePrimaryButton: notAllowed
              }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick", "disablePrimaryButton"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["showDialog"])
    ]),
    _: 1
  }, 512))
}