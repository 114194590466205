import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-2 label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, {
    "layout-template": "centered-tab",
    "full-width": true
  }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Student Details",
        subTitle: _ctx.studentDetails.fullName
      }, null, 8, ["subTitle"])
    ]),
    "tab-header": _withCtx(() => [
      _createVNode(_component_v_tabs, {
        stacked: "",
        color: "primary"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item.label,
              to: item.command(),
              ripple: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_icon, {
                    start: "",
                    icon: item.icon,
                    class: "px-1"
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}