import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_tooltip_icon = _resolveComponent("tooltip-icon")!
  const _component_title_value = _resolveComponent("title-value")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_overaward_details = _resolveComponent("overaward-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header_container, { enableCardView: true }, {
      header: _withCtx(() => [
        _createVNode(_component_body_header, {
          title: "Overaward balances",
          subTitle: "A balance of overawards broken down by award type"
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_title_value, {
                  propertyValue: 
            _ctx.formatCurrency(_ctx.overawardBalance.overawardBalanceValues?.CSLF)
          
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.FullTimeAwardTypes.CSLF) + " ", 1),
                    _createVNode(_component_tooltip_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getAwardDescription(_ctx.FullTimeAwardTypes.CSLF)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["propertyValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_title_value, {
                  propertyValue: 
            _ctx.formatCurrency(_ctx.overawardBalance.overawardBalanceValues?.CSLP)
          
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.PartTimeAwardTypes.CSLP) + " ", 1),
                    _createVNode(_component_tooltip_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getAwardDescription(_ctx.PartTimeAwardTypes.CSLP)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["propertyValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_title_value, {
                  propertyValue: 
            _ctx.formatCurrency(_ctx.overawardBalance.overawardBalanceValues?.BCSL)
          
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.FullTimeAwardTypes.BCSL) + " ", 1),
                    _createVNode(_component_tooltip_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getAwardDescription(_ctx.FullTimeAwardTypes.BCSL)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["propertyValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_overaward_details, {
      studentId: _ctx.studentId,
      showAddedBy: _ctx.showAddedBy,
      allowManualOverawardDeduction: _ctx.allowManualOverawardDeduction,
      onManualOverawardAdded: _ctx.loadOverawardBalance
    }, null, 8, ["studentId", "showAddedBy", "allowManualOverawardDeduction", "onManualOverawardAdded"])
  ], 64))
}