import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_institution_user_summary = _resolveComponent("institution-user-summary")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage institution",
        "data-cy": "manageUserHeader",
        subTitle: "Manage Users"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_institution_user_summary, {
        hasBusinessGuid: _ctx.hasBusinessGuid,
        canSearchBCeIDUsers: true
      }, null, 8, ["hasBusinessGuid"])
    ]),
    _: 1
  }))
}