import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider_inset_opaque = _resolveComponent("v-divider-inset-opaque")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_banner = _resolveComponent("banner")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_offering_form_submit = _resolveComponent("offering-form-submit")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Program detail",
        routeLocation: _ctx.routeLocation,
        subTitle: "Edit Offering"
      }, {
        buttons: _withCtx(() => [
          _createVNode(_component_v_row, { class: "p-0 m-0" }, {
            default: _withCtx(() => [
              (_ctx.hasExistingApplication)
                ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "ml-2",
                        color: "primary",
                        "prepend-icon": "fa:fa fa-chevron-circle-down"
                      }, props), {
                        default: _withCtx(() => [
                          _createTextVNode("Edit Actions")
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, {
                        "active-class": "active-list-item",
                        density: "compact",
                        "bg-color": "default",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              _createVNode(_component_v_list_item, { value: index }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, {
                                    onClick: item.command
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1032, ["value"]),
                              (index < _ctx.items.length - 1)
                                ? (_openBlock(), _createBlock(_component_v_divider_inset_opaque, { key: index }))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routeLocation"])
    ]),
    alerts: _withCtx(() => [
      (_ctx.hasExistingApplication)
        ? (_openBlock(), _createBlock(_component_banner, {
            key: 0,
            class: "mb-2",
            type: _ctx.BannerTypes.Success,
            header: "Students have applied financial aid for this offering",
            summary: "You can still make changes to the name. If you need edit the locked fields, please click on the edit actions menu and request to edit."
          }, null, 8, ["type"]))
        : _createCommentVNode("", true)
    ]),
    "details-header": _withCtx(() => [
      _createVNode(_component_program_offering_detail_header, {
        headerDetails: {
          ..._ctx.initialData,
          status: _ctx.initialData.offeringStatus,
        }
      }, null, 8, ["headerDetails"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_offering_form_submit, {
        submitLabel: "Update offering now",
        data: _ctx.initialData,
        formMode: _ctx.formMode,
        locationId: _ctx.locationId,
        programId: _ctx.programId,
        enableValidationsOnInit: true,
        onSubmit: _ctx.submit,
        onCancel: _ctx.goBack
      }, null, 8, ["data", "formMode", "locationId", "programId", "onSubmit", "onCancel"])
    ]),
    _: 1
  }))
}