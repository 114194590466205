import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_student_overaward_details = _resolveComponent("student-overaward-details")!
  const _component_student_loan_balance_part_time = _resolveComponent("student-loan-balance-part-time")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, { enableCardView: false }, {
    default: _withCtx(() => [
      _createVNode(_component_student_overaward_details, {
        studentId: _ctx.studentId,
        showAddedBy: true,
        allowManualOverawardDeduction: true
      }, null, 8, ["studentId"]),
      _createVNode(_component_student_loan_balance_part_time, { "student-id": _ctx.studentId }, null, 8, ["student-id"])
    ]),
    _: 1
  }))
}