import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  "data-cy": "locationName",
  class: "category-header-medium mx-1"
}
const _hoisted_2 = {
  "data-cy": "institutionCode",
  class: "label-value muted-content clearfix"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_status_chip_designation_agreement = _resolveComponent("status-chip-designation-agreement")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_title_value = _resolveComponent("title-value")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "All locations",
        recordsCount: _ctx.institutionLocationList.length
      }, {
        actions: _withCtx(() => [
          (_ctx.clientType === _ctx.ClientIdType.Institution)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "float-right",
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToAddNewLocation())),
                "prepend-icon": "fa:fa fa-plus-circle",
                "data-cy": "addLocation"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add location ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["recordsCount"])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.institutionLocationList, (item) => {
        return (_openBlock(), _createBlock(_component_content_group, {
          class: "mb-4",
          key: item,
          "data-cy": "institutionLocation"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_v_icon, { icon: "mdi-map-marker-outline" }),
                      _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1),
                      _createVNode(_component_status_chip_designation_agreement, {
                        "data-cy": "institutionDesignationStatus",
                        status: item.designationStatus
                      }, null, 8, ["status"])
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_check_permission_role, {
                      role: _ctx.Role.InstitutionEditLocationDetails
                    }, {
                      default: _withCtx(({ notAllowed }) => [
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          class: "float-right",
                          variant: "text",
                          disabled: notAllowed,
                          onClick: ($event: any) => (_ctx.$emit('editLocation', item.id)),
                          "prepend-icon": "fa:fa fa-gear",
                          "data-cy": "editLocation"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Edit ")
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"])
                      ]),
                      _: 2
                    }, 1032, ["role"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_title_value, { propertyTitle: "Address" }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressList(item), (addressLine) => {
                      return (_openBlock(), _createElementBlock("span", {
                        class: "label-value muted-content clearfix",
                        key: addressLine,
                        "data-cy": "institutionAddress1"
                      }, _toDisplayString(addressLine), 1))
                    }), 128))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_title_value, { propertyTitle: "Primary contact" }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primaryContactList(item), (contactLine) => {
                      return (_openBlock(), _createElementBlock("span", {
                        class: "label-value muted-content clearfix",
                        key: contactLine,
                        "data-cy": "institutionPrimaryContact"
                      }, _toDisplayString(contactLine), 1))
                    }), 128))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_title_value, { propertyTitle: "Institution location code" }),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(item.institutionCode), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}