import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mx-2 label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_chip_designation_agreement = _resolveComponent("status-chip-designation-agreement")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, {
    "layout-template": "centered-tab",
    fullWidth: true
  }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Institution Details",
        subTitle: _ctx.institutionBasicDetail.operatingName
      }, {
        "sub-title-details": _withCtx(() => [
          (_ctx.institutionBasicDetail.designationStatus)
            ? (_openBlock(), _createBlock(_component_status_chip_designation_agreement, {
                key: 0,
                class: "ml-4 mt-1",
                status: _ctx.institutionBasicDetail.designationStatus
              }, null, 8, ["status"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["subTitle"])
    ]),
    "tab-header": _withCtx(() => [
      _createVNode(_component_v_tabs, {
        model: _ctx.tab,
        stacked: "",
        color: "primary"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item,
              value: item.value,
              to: item.command(),
              ripple: false
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_icon, {
                    start: "",
                    icon: item.icon,
                    class: "px-1"
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(item.label), 1)
                ])
              ]),
              _: 2
            }, 1032, ["value", "to"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}