import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_profile_form = _resolveComponent("student-profile-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_full_page_container, null, {
      header: _withCtx(() => [
        _createVNode(_component_header_navigator, {
          title: "Accounts",
          subTitle: "View request",
          routeLocation: _ctx.pendingAccountsRoute
        }, {
          buttons: _withCtx(() => [
            _createVNode(_component_v_row, { class: "p-0 m-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_check_permission_role, {
                  role: _ctx.Role.StudentApproveDeclineAccountRequests
                }, {
                  default: _withCtx(({ notAllowed }) => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      class: "mr-2",
                      variant: "outlined",
                      onClick: _ctx.declineStudentAccount,
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Deny request")
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"]),
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      onClick: _ctx.createStudentAccount,
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Create student account")
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"])
                  ]),
                  _: 1
                }, 8, ["role"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["routeLocation"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_student_profile_form, {
          processing: _ctx.processing,
          formModel: _ctx.initialData,
          onLoaded: _ctx.formLoaded
        }, null, 8, ["processing", "formModel", "onLoaded"])
      ]),
      _: 1
    }),
    _createVNode(_component_check_permission_role, {
      role: _ctx.Role.StudentApproveDeclineAccountRequests
    }, {
      default: _withCtx(({ notAllowed }) => [
        _createVNode(_component_confirm_modal, {
          title: "Create student account",
          text: "Attention: Approved account requests are final and cannot be deactivated. Please ensure all supporting documentation has been carefully reviewed prior to approval.",
          okLabel: "Create account now",
          ref: "createStudentAccountModal",
          disablePrimaryButton: notAllowed
        }, null, 8, ["disablePrimaryButton"]),
        _createVNode(_component_confirm_modal, {
          title: "Deny request for a student account",
          text: "Denying the request means that the student will not be able to access the system using a Basic BCeID.",
          ref: "declineStudentAccountModal",
          okLabel: "Deny request now",
          disablePrimaryButton: notAllowed
        }, null, 8, ["disablePrimaryButton"])
      ]),
      _: 1
    }, 8, ["role"])
  ], 64))
}