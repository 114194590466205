import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_institution_user_summary = _resolveComponent("institution-user-summary")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_institution_user_summary, {
        institutionId: _ctx.institutionId,
        hasBusinessGuid: _ctx.hasBusinessGuid,
        allowBasicBCeIDCreation: true
      }, null, 8, ["institutionId", "hasBusinessGuid"])
    ]),
    _: 1
  }))
}