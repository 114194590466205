import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_designation_agreement_form = _resolveComponent("designation-agreement-form")!
  const _component_approve_deny_designation_modal = _resolveComponent("approve-deny-designation-modal")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered-card-tab" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: _ctx.navigationTitle,
        subTitle: "View designation agreement",
        routeLocation: _ctx.routeLocation
      }, {
        buttons: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.InstitutionApproveDeclineDesignation
          }, {
            default: _withCtx(({ notAllowed }) => [
              (_ctx.showActionButtons)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "primary",
                    variant: "outlined",
                    "data-cy": "declinedDesignationAgreementButton",
                    disabled: notAllowed,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateDesignation(_ctx.DesignationAgreementStatus.Declined)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Decline")
                    ]),
                    _: 2
                  }, 1032, ["disabled"]))
                : _createCommentVNode("", true),
              (_ctx.showActionButtons)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    class: "ml-2",
                    color: "primary",
                    disabled: notAllowed,
                    "data-cy": "approvedDesignationAgreementButton",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateDesignation(_ctx.DesignationAgreementStatus.Approved)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Approve designation")
                    ]),
                    _: 2
                  }, 1032, ["disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["title", "routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_designation_agreement_form, {
        model: _ctx.designationFormModel,
        "view-only": true
      }, null, 8, ["model"]),
      _createVNode(_component_approve_deny_designation_modal, {
        ref: "approveDenyDesignationModal",
        designation: _ctx.updateDesignationModel,
        processing: _ctx.processing
      }, null, 8, ["designation", "processing"])
    ]),
    _: 1
  }))
}