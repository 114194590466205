import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_banner = _resolveComponent("banner")!
  const _component_location_edit_form = _resolveComponent("location-edit-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage locations",
        routeLocation: _ctx.goBackRouteParams,
        subTitle: "Edit Location",
        "data-cy": "editLocationHeader"
      }, null, 8, ["routeLocation"])
    ]),
    alerts: _withCtx(() => [
      _createVNode(_component_banner, {
        type: _ctx.BannerTypes.Info,
        header: "How to update information",
        summary: "Please email a request to update the location details. For the primary contact, you can update it without a request."
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, { color: "info" }, {
            default: _withCtx(() => [
              _createTextVNode("Email designat@gov.bc.ca")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_location_edit_form, {
        locationData: _ctx.initialData,
        onUpdateInstitutionLocation: _ctx.updateInstitutionLocation
      }, null, 8, ["locationData", "onUpdateInstitutionLocation"])
    ]),
    _: 1
  }))
}