import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_student_restrictions = _resolveComponent("student-restrictions", true)!
  const _component_student_scholastic_standing_limited_history = _resolveComponent("student-scholastic-standing-limited-history")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, { enableCardView: false }, {
    default: _withCtx(() => [
      _createVNode(_component_student_restrictions, {
        studentId: _ctx.studentId,
        canAddRestrictions: true,
        canResolveRestriction: true
      }, null, 8, ["studentId"]),
      _createVNode(_component_student_scholastic_standing_limited_history, { studentId: _ctx.studentId }, null, 8, ["studentId"])
    ]),
    _: 1
  }))
}