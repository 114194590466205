import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_application_header_title = _resolveComponent("application-header-title")!
  const _component_assessment_award = _resolveComponent("assessment-award", true)!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessments",
        subTitle: "View Assessment",
        routeLocation: {
          name: _ctx.AESTRoutesConst.ASSESSMENTS_SUMMARY,
          params: { applicationId: _ctx.applicationId, studentId: _ctx.studentId },
        }
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_application_header_title, { "application-id": _ctx.applicationId }, null, 8, ["application-id"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_assessment_award, {
        "assessment-award-data": _ctx.assessmentAwardData,
        "notice-of-assessment-route": _ctx.noticeOfAssessmentRoute,
        "allow-confirm-enrolment": true,
        "allow-final-award-extended-information": true,
        onConfirmEnrolment: _ctx.confirmEnrolment
      }, null, 8, ["assessment-award-data", "notice-of-assessment-route", "onConfirmEnrolment"])
    ]),
    _: 1
  }))
}