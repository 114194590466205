import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_chip_requested_assessment = _resolveComponent("status-chip-requested-assessment")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_appeal_requests_approval_form = _resolveComponent("appeal-requests-approval-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header, { title: "Student change" }, {
      "status-chip": _withCtx(() => [
        _createVNode(_component_status_chip_requested_assessment, { status: _ctx.appealStatus }, null, 8, ["status"])
      ]),
      _: 1
    }),
    _createVNode(_component_appeal_requests_approval_form, {
      studentAppealRequests: _ctx.studentAppealRequests,
      readOnly: _ctx.readOnly,
      showApprovalDetails: _ctx.showApprovalDetails,
      onSubmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitted', $event)))
    }, _createSlots({ _: 2 }, [
      (!_ctx.readOnly)
        ? {
            name: "approval-actions",
            fn: _withCtx(({ submit }) => [
              _createVNode(_component_v_row, {
                justify: "center",
                class: "m-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    variant: "outlined",
                    class: "mr-2",
                    "data-cy": "cancelApprovalRequestButton",
                    onClick: _ctx.gotToAssessmentsSummary
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cancel")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_check_permission_role, {
                    role: _ctx.Role.StudentApproveDeclineAppeals
                  }, {
                    default: _withCtx(({ notAllowed }) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        class: "ml-2",
                        "data-cy": "completeStudentRequest",
                        onClick: submit,
                        disabled: notAllowed
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Complete student request ")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["role"])
                ]),
                _: 2
              }, 1024)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["studentAppealRequests", "readOnly", "showApprovalDetails"])
  ], 64))
}