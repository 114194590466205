import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_supplier = _resolveComponent("status-chip-supplier")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_edit_c_a_s_supplier_info_modal = _resolveComponent("edit-c-a-s-supplier-info-modal")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "CAS Supplier Information",
            subTitle: "The top row in the table below is the most current CAS information for the student."
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_check_permission_role, {
                role: _ctx.Role.AESTEditCASSupplierInfo
              }, {
                default: _withCtx(({ notAllowed }) => [
                  _createVNode(_component_v_btn, {
                    class: "float-right",
                    color: "primary",
                    "data-cy": "editCASSupplierInfoButton",
                    disabled: notAllowed,
                    onClick: _ctx.addCASSupplierInfo,
                    "prepend-icon": "fa:fa fa-plus-circle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Edit info")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ]),
                _: 1
              }, 8, ["role"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.casSupplierInfo?.items?.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: _ctx.CASSupplierInformationHeaders,
                    items: _ctx.casSupplierInfo.items,
                    "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                    "items-per-page-options": _ctx.ITEMS_PER_PAGE
                  }, {
                    [`item.dateCreated`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(item.dateCreated)), 1)
                    ]),
                    [`item.supplierNumber`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.supplierNumber), 1)
                    ]),
                    [`item.supplierProtected`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.booleanToYesNo(item.supplierProtected)), 1)
                    ]),
                    [`item.supplierStatus`]: _withCtx(({ item }) => [
                      _createVNode(_component_status_chip_supplier, {
                        status: item.supplierStatus
                      }, null, 8, ["status"])
                    ]),
                    [`item.isValid`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.booleanToYesNo(item.isValid)), 1)
                    ]),
                    [`item.supplierSiteCode`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.supplierSiteCode), 1)
                    ]),
                    [`item.addressLine1`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.addressLine1)), 1)
                    ]),
                    [`item.siteStatus`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.siteStatus)), 1)
                    ]),
                    [`item.siteProtected`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.siteProtected)), 1)
                    ]),
                    _: 2
                  }, 1032, ["headers", "items", "items-per-page", "items-per-page-options"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          }),
          _createVNode(_component_edit_c_a_s_supplier_info_modal, {
            ref: "addCASSupplierModal",
            allowedRole: _ctx.Role.AESTEditCASSupplierInfo
          }, null, 8, ["allowedRole"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}