import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_banner = _resolveComponent("banner")!
  const _component_institution_profile_form = _resolveComponent("institution-profile-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage institution",
        "data-cy": "manageProfileHeader",
        subTitle: "Manage Profile"
      })
    ]),
    alerts: _withCtx(() => [
      _createVNode(_component_banner, {
        type: _ctx.BannerTypes.Info,
        header: "How to update information",
        summary: "Please send us an email to request a change. For your institution's mailing address, you can update it without emailing a request."
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, { color: "info" }, {
            default: _withCtx(() => [
              _createTextVNode("Email designat@gov.bc.ca")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_institution_profile_form, {
        profileData: _ctx.institutionProfileModel,
        onSubmitInstitutionProfile: _ctx.updateInstitution
      }, null, 8, ["profileData", "onSubmitInstitutionProfile"])
    ]),
    _: 1
  }))
}