import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_extend_time = _resolveComponent("confirm-extend-time")!

  return (_openBlock(), _createElementBlock("div", {
    class: "v-main body-background",
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setLastActivityTime && _ctx.setLastActivityTime(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setLastActivityTime && _ctx.setLastActivityTime(...args))),
    onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setLastActivityTime && _ctx.setLastActivityTime(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_confirm_extend_time, {
      ref: "extendTimeModal",
      countdown: _ctx.countdown,
      onDialogClosedEvent: _ctx.extendUserSessionTime
    }, null, 8, ["countdown", "onDialogClosedEvent"])
  ], 32))
}