import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_restriction_banner = _resolveComponent("restriction-banner")!
  const _component_check_valid_s_i_n_banner = _resolveComponent("check-valid-s-i-n-banner")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, {
    "layout-template": _ctx.layoutTemplate,
    "full-width": _ctx.fullWidth
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header")
    ]),
    alerts: _withCtx(() => [
      (!_ctx.hideRestriction)
        ? (_openBlock(), _createBlock(_component_restriction_banner, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_check_valid_s_i_n_banner),
      _renderSlot(_ctx.$slots, "alerts")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["layout-template", "full-width"]))
}