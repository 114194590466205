import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio = _resolveComponent("formio")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!

  return (_openBlock(), _createBlock(_component_modal_dialog_base, {
    title: _ctx.title,
    showDialog: _ctx.showDialog,
    maxWidth: _ctx.maxWidth
  }, {
    content: _withCtx(() => [
      _createVNode(_component_formio, {
        formName: _ctx.formName,
        data: _ctx.formData,
        onLoaded: _ctx.formLoaded
      }, null, 8, ["formName", "data", "onLoaded"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "actions", {
          cancel: _ctx.cancel,
          submit: _ctx.submit
        })
      ])
    ]),
    _: 3
  }, 8, ["title", "showDialog", "maxWidth"]))
}