import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_title_value = _resolveComponent("header-title-value")!
  const _component_v_divider_vertical_opaque = _resolveComponent("v-divider-vertical-opaque")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_header_title_value, {
          title: "Submitted date",
          value: 
          _ctx.headerDetails.submittedDate
            ? _ctx.dateOnlyLongString(_ctx.headerDetails.submittedDate)
            : '-'
        
        }, null, 8, ["value"]),
        (_ctx.headerDetails.institutionName)
          ? (_openBlock(), _createBlock(_component_v_divider_vertical_opaque, {
              key: 0,
              class: "mx-2 my-0"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_header_title_value, { title: "Institution" }, {
          value: _withCtx(() => [
            _createElementVNode("span", {
              class: "link-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToInstitutionProfile()))
            }, _toDisplayString(_ctx.headerDetails.institutionName), 1)
          ]),
          _: 1
        }),
        (_ctx.headerDetails.locationName)
          ? (_openBlock(), _createBlock(_component_v_divider_vertical_opaque, {
              key: 1,
              class: "mx-2 my-0"
            }))
          : _createCommentVNode("", true),
        (_ctx.headerDetails.locationName)
          ? (_openBlock(), _createBlock(_component_header_title_value, {
              key: 2,
              title: "Location",
              value: _ctx.headerDetails.locationName
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showReviewDetails)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_header_title_value, {
              iconLabel: _ctx.iconLabel,
              iconColor: _ctx.iconColor,
              title: _ctx.assessedByLabel,
              value: _ctx.assessedByLabelValue
            }, null, 8, ["iconLabel", "iconColor", "title", "value"]),
            _createVNode(_component_v_divider_vertical_opaque, { class: "mx-2 my-0" }),
            _createVNode(_component_header_title_value, {
              title: _ctx.assessedDateLabel,
              value: _ctx.assessedDateLabelValue
            }, null, 8, ["title", "value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}