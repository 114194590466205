import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_profile_form = _resolveComponent("student-profile-form")!
  const _component_student_page_container = _resolveComponent("student-page-container")!
  const _component_p_d_status_application_modal = _resolveComponent("p-d-status-application-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_student_page_container, null, {
      header: _withCtx(() => [
        _createVNode(_component_header_navigator, {
          title: "Student",
          subTitle: "Profile"
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_student_profile_form, {
          processing: _ctx.processing,
          formModel: _ctx.initialData,
          onSubmitted: _ctx.submitted,
          onCustomEvent: _ctx.showPDApplicationModal
        }, null, 8, ["processing", "formModel", "onSubmitted", "onCustomEvent"])
      ]),
      _: 1
    }),
    _createVNode(_component_p_d_status_application_modal, { ref: "pdStatusApplicationModal" }, null, 512)
  ], 64))
}