import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_designation_agreement_summary = _resolveComponent("designation-agreement-summary")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "Designation agreements",
            recordsCount: _ctx.designations?.length
          }, null, 8, ["recordsCount"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_designation_agreement_summary, {
            designations: _ctx.designations,
            toggleMessage: "No designation agreements found",
            onViewDesignation: _ctx.goToViewDesignation
          }, null, 8, ["designations", "onViewDesignation"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}