import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_assessment_award_details = _resolveComponent("assessment-award-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header, {
      title: "Funding summary",
      subTitle: "Below is the summary from your assessment. To view your Notice of Assessment, click on view assessment."
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          class: "float-right",
          color: "primary",
          "prepend-icon": "fa:far fa-file-lines",
          onClick: _ctx.goToNoticeOfAssessment
        }, {
          default: _withCtx(() => [
            _createTextVNode("View assessment")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_assessment_award_details, {
      assessmentAwardData: _ctx.assessmentAwardData,
      allowConfirmEnrolment: _ctx.allowConfirmEnrolment,
      "allow-final-award-extended-information": _ctx.allowFinalAwardExtendedInformation,
      onConfirmEnrolment: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirmEnrolment', $event)))
    }, null, 8, ["assessmentAwardData", "allowConfirmEnrolment", "allow-final-award-extended-information"])
  ], 64))
}