import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_banner = _resolveComponent("banner")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_offering_form = _resolveComponent("offering-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Program detail",
        routeLocation: _ctx.routeLocation,
        subTitle: "View Offering"
      }, null, 8, ["routeLocation"])
    ]),
    alerts: _withCtx(() => [
      (_ctx.hasExistingApplication)
        ? (_openBlock(), _createBlock(_component_banner, {
            key: 0,
            class: "mb-2",
            type: _ctx.BannerTypes.Success,
            header: "Students have applied financial aid for this offering",
            summary: "You can still make changes to the name. If you need edit the locked fields, please click on the edit actions menu and request to edit."
          }, null, 8, ["type"]))
        : _createCommentVNode("", true)
    ]),
    "details-header": _withCtx(() => [
      _createVNode(_component_program_offering_detail_header, {
        class: "m-4",
        headerDetails: {
          ..._ctx.initialData,
          status: _ctx.initialData.offeringStatus,
        }
      }, null, 8, ["headerDetails"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_offering_form, { data: _ctx.initialData }, null, 8, ["data"])
    ]),
    _: 1
  }))
}