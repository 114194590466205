import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage locations",
        routeLocation: { name: _ctx.InstitutionRoutesConst.MANAGE_LOCATIONS },
        subTitle: "Add Location",
        "data-cy": "addLocationHeader"
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "institutionLocation",
        onSubmitted: _ctx.submitted
      }, {
        actions: _withCtx(({ submit }) => [
          _createVNode(_component_footer_buttons, {
            processing: _ctx.processing,
            primaryLabel: "Submit",
            onPrimaryClick: submit,
            showSecondaryButton: false
          }, null, 8, ["processing", "onPrimaryClick"])
        ]),
        _: 1
      }, 8, ["onSubmitted"])
    ]),
    _: 1
  }))
}