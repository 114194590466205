import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_institution_profile_form = _resolveComponent("institution-profile-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage institutions",
        subTitle: "Create Institution"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_institution_profile_form, {
        profileData: _ctx.institutionProfileModel,
        onSubmitInstitutionProfile: _ctx.createInstitution,
        processing: _ctx.processing,
        submitLabel: "Create profile",
        allowedRole: _ctx.Role.AESTCreateInstitution
      }, null, 8, ["profileData", "onSubmitInstitutionProfile", "processing", "allowedRole"])
    ]),
    _: 1
  }))
}