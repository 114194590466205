import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "label-bold black-color"
}
const _hoisted_2 = {
  key: 1,
  class: "label-value black-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_slider = _resolveComponent("v-slider")!

  return (_openBlock(), _createBlock(_component_v_slider, {
    modelValue: _ctx.currentStatus,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStatus) = $event)),
    ticks: _ctx.ticks,
    max: _ctx.maximumAllowedProgressValue,
    step: "1",
    "show-ticks": "always",
    "tick-size": "0",
    "track-color": "readonly",
    "track-fill-color": _ctx.progressBarColor,
    "thumb-size": _ctx.initialStepSize,
    "thumb-color": _ctx.initialStepColor,
    "track-size": "20",
    readonly: "",
    disabled: _ctx.disabled,
    class: "stepper-progress-slider mt-n2"
  }, {
    "tick-label": _withCtx(({ tick, index }) => [
      (index === _ctx.currentStatus)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(tick.label) + " ", 1),
            (_ctx.progressLabelIcon)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  icon: _ctx.progressLabelIcon,
                  size: 20,
                  color: _ctx.progressLabelIconColor
                }, null, 8, ["icon", "color"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(tick.label), 1))
    ]),
    _: 1
  }, 8, ["modelValue", "ticks", "max", "track-fill-color", "thumb-size", "thumb-color", "disabled"]))
}