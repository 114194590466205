import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_application_status_tracker_banner = _resolveComponent("application-status-tracker-banner")!

  return (_openBlock(), _createBlock(_component_application_status_tracker_banner, {
    label: "Your application was cancelled",
    icon: "fa:fas fa-exclamation-circle",
    "icon-color": "danger",
    "background-color": "error-bg"
  }, {
    content: _withCtx(() => [
      _createTextVNode("Your application was cancelled on " + _toDisplayString(_ctx.dateOnlyLongString(_ctx.cancelledDate)) + ". However you can still view your application by clicking on the \"Application actions\" button. If you did not cancel your application, it means that your application has been cancelled due to changes in the study period made by your institution. Please contact the Financial Aid Officer from your institution if you require more information about the change. You'll need to submit a new application with the updated study period information to proceed. ", 1)
    ]),
    _: 1
  }))
}