import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/playful-cat.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "muted-content-strong" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.toggled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_row, {
          justify: "center",
          class: "mt-1"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "image", {}, () => [
              _createVNode(_component_v_img, {
                height: "150",
                alt: "There's nothing here yet",
                src: _imports_0
              })
            ])
          ]),
          _: 3
        }),
        _createVNode(_component_v_row, { justify: "center mt-5" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "message", {}, () => [
              _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.message), 1)
            ])
          ]),
          _: 3
        })
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}