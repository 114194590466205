import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    justify: _ctx.justify,
    class: "mt-2 mb-1"
  }, {
    default: _withCtx(() => [
      (_ctx.showSecondaryButton)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            disabled: _ctx.processing,
            color: _ctx.secondaryButtonColor,
            variant: _ctx.secondaryButtonVariant,
            class: "mr-2",
            "data-cy": "secondaryFooterButton",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('secondaryClick')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.secondaryLabel), 1)
            ]),
            _: 1
          }, 8, ["disabled", "color", "variant"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "primary-buttons", {
        disabled: _ctx.processing || _ctx.disablePrimaryButton
      }, () => [
        (_ctx.showPrimaryButton)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              disabled: _ctx.processing || _ctx.disablePrimaryButton,
              class: "ml-2",
              variant: "elevated",
              "data-cy": "primaryFooterButton",
              color: _ctx.primaryButtonColor,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('primaryClick'))),
              loading: _ctx.processing
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.primaryLabel), 1)
              ]),
              _: 1
            }, 8, ["disabled", "color", "loading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["justify"]))
}