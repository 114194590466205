import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_appeal_requests_approval = _resolveComponent("student-appeal-requests-approval")!
  const _component_student_page_container = _resolveComponent("student-page-container")!

  return (_openBlock(), _createBlock(_component_student_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Application details",
        subTitle: "View Request",
        routeLocation: {
          name: _ctx.StudentRoutesConst.STUDENT_APPLICATION_DETAILS,
          params: {
            id: _ctx.applicationId,
          },
        }
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_student_appeal_requests_approval, {
        appealId: _ctx.appealId,
        readOnlyForm: true
      }, null, 8, ["appealId"])
    ]),
    _: 1
  }))
}