import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { "data-cy": "programCIP" }
const _hoisted_2 = { "data-cy": "programName" }
const _hoisted_3 = { "data-cy": "programCredential" }
const _hoisted_4 = { "data-cy": "programStudyPeriods" }
const _hoisted_5 = { "data-cy": "programStatus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_program = _resolveComponent("status-chip-program")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: _ctx.locationName,
        "data-cy": "programsHeader",
        subTitle: "Programs"
      }, null, 8, ["title"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "All programs",
        recordsCount: _ctx.programAndCount?.count
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_row, { class: "m-0 p-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                label: "Search Program",
                variant: "outlined",
                modelValue: _ctx.searchBox,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchBox) = $event)),
                "data-cy": "searchBox",
                onKeyup: _withKeys(_ctx.searchProgramTable, ["enter"]),
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "auto"
              }, null, 8, ["modelValue", "onKeyup"]),
              _createVNode(_component_v_btn, {
                class: "ml-2",
                color: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToAddNewProgram())),
                "data-cy": "createProgram",
                "prepend-icon": "fa:fa fa-plus-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Create program ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["recordsCount"]),
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.programAndCount?.count,
            message: "You don't have programs yet"
          }, {
            default: _withCtx(() => [
              (_ctx.programAndCount?.count)
                ? (_openBlock(), _createBlock(_component_v_data_table_server, {
                    key: 0,
                    headers: _ctx.ProgramSummaryHeaders,
                    items: _ctx.programAndCount?.results,
                    "items-length": _ctx.programAndCount?.count,
                    loading: _ctx.loading,
                    "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                    "items-per-page-options": _ctx.ITEMS_PER_PAGE,
                    "onUpdate:options": _ctx.paginationAndSortEvent
                  }, {
                    item: _withCtx(({ item }) => [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_1, _toDisplayString(item.cipCode), 1),
                        _createElementVNode("td", _hoisted_2, _toDisplayString(item.programName), 1),
                        _createElementVNode("td", _hoisted_3, _toDisplayString(item.credentialType), 1),
                        _createElementVNode("td", _hoisted_4, _toDisplayString(item.totalOfferings), 1),
                        _createElementVNode("td", _hoisted_5, [
                          _createVNode(_component_status_chip_program, {
                            status: item.programStatus,
                            "is-active": item.isActive && !item.isExpired
                          }, null, 8, ["status", "is-active"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: ($event: any) => (_ctx.goToViewProgram(item.programId)),
                            "data-cy": "viewProgram"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("View")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "items", "items-length", "loading", "items-per-page", "items-per-page-options", "onUpdate:options"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}