import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_ctx.relevantAnnouncements.length)
    ? (_openBlock(), _createBlock(_component_banner, {
        key: 0,
        header: "System Announcements",
        type: _ctx.BannerTypes.Warning
      }, {
        content: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantAnnouncements, (announcement, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("span", _hoisted_1, _toDisplayString(announcement.messageTitle), 1),
              _createTextVNode(" - "),
              _createElementVNode("i", null, "added " + _toDisplayString(_ctx.dateOnlyLongString(announcement.startDate)), 1),
              _createElementVNode("p", null, _toDisplayString(announcement.message), 1)
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["type"]))
    : _createCommentVNode("", true)
}