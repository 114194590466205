import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_formio_container = _resolveComponent("formio-container")!

  return (_openBlock(), _createBlock(_component_formio_container, {
    formName: "studentProfile",
    formData: _ctx.formModel,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loaded', $event))),
    onSubmitted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submitted', $event))),
    onCustomEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('customEvent', $event)))
  }, {
    actions: _withCtx(({ submit }) => [
      (_ctx.showActionButtons)
        ? (_openBlock(), _createBlock(_component_footer_buttons, {
            key: 0,
            processing: _ctx.processing,
            onPrimaryClick: submit,
            primaryLabel: _ctx.saveLabel,
            showSecondaryButton: false
          }, null, 8, ["processing", "onPrimaryClick", "primaryLabel"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["formData"]))
}