import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_application_header_title = _resolveComponent("application-header-title")!
  const _component_application_progress_bar = _resolveComponent("application-progress-bar")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Application history",
        routeLocation: {
          name: _ctx.AESTRoutesConst.STUDENT_APPLICATIONS,
          params: { studentId: _ctx.studentId },
        },
        subTitle: "Application Status"
      }, null, 8, ["routeLocation"]),
      _createVNode(_component_application_header_title, { "application-id": _ctx.applicationId }, null, 8, ["application-id"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_application_progress_bar, { "application-id": _ctx.applicationId }, null, 8, ["application-id"])
    ]),
    _: 1
  }))
}