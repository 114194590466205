import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_add_new_s_i_n = _resolveComponent("add-new-s-i-n")!
  const _component_add_expiry_date = _resolveComponent("add-expiry-date")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "Social Insurance Number",
            subTitle: "The first row will always be the student's current active SIN.",
            recordsCount: _ctx.studentSINValidations?.length
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_check_permission_role, {
                role: _ctx.Role.StudentAddNewSIN
              }, {
                default: _withCtx(({ notAllowed }) => [
                  _createVNode(_component_v_btn, {
                    class: "float-right",
                    color: "primary",
                    "data-cy": "addNewSINButton",
                    disabled: _ctx.processingNewSIN || notAllowed,
                    onClick: _ctx.addNewSIN,
                    "prepend-icon": "fa:fa fa-plus-circle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Add new SIN")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ]),
                _: 1
              }, 8, ["role"])
            ]),
            _: 1
          }, 8, ["recordsCount"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.studentSINValidations?.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DataTable, {
                    value: _ctx.studentSINValidations,
                    paginator: true,
                    rows: _ctx.DEFAULT_PAGE_LIMIT,
                    rowsPerPageOptions: _ctx.PAGINATION_LIST,
                    breakpoint: "1380px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "createdAtFormatted",
                        header: "Date created"
                      }),
                      _createVNode(_component_Column, {
                        field: "sinFormatted",
                        header: "SIN",
                        bodyClass: "text-nowrap"
                      }),
                      _createVNode(_component_Column, {
                        field: "isValidSINFormatted",
                        header: "SIN validated"
                      }),
                      _createVNode(_component_Column, {
                        field: "sinStatus",
                        header: "Response code"
                      }),
                      _createVNode(_component_Column, {
                        field: "validSINCheckFormatted",
                        header: "SIN accepted"
                      }),
                      _createVNode(_component_Column, {
                        field: "validFirstNameCheckFormatted",
                        header: "First name"
                      }),
                      _createVNode(_component_Column, {
                        field: "validLastNameCheckFormatted",
                        header: "Last name"
                      }),
                      _createVNode(_component_Column, {
                        field: "validBirthdateCheckFormatted",
                        header: "Date of birth"
                      }),
                      _createVNode(_component_Column, {
                        field: "validGenderCheckFormatted",
                        header: "Gender"
                      }),
                      _createVNode(_component_Column, {
                        field: "sinExpiryDateFormatted",
                        header: "Expiry date"
                      }),
                      _createVNode(_component_Column, { header: "Action" }, {
                        body: _withCtx((slotProps) => [
                          _createVNode(_component_check_permission_role, {
                            role: _ctx.Role.StudentAddSINExpiry
                          }, {
                            default: _withCtx(({ notAllowed }) => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                disabled: 
                        !slotProps.data.temporarySIN ||
                        !!slotProps.data.sinExpiryDate ||
                        _ctx.processingEditExpiryDate ||
                        notAllowed
                      ,
                                onClick: ($event: any) => (_ctx.addExpiryDate(slotProps.data.id))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Add expiry date")
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"])
                            ]),
                            _: 2
                          }, 1032, ["role"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "rows", "rowsPerPageOptions"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          }),
          _createVNode(_component_add_new_s_i_n, {
            ref: "addNewSINModal",
            allowedRole: _ctx.Role.StudentAddNewSIN
          }, null, 8, ["allowedRole"]),
          _createVNode(_component_add_expiry_date, {
            ref: "addExpiryDateModal",
            allowedRole: _ctx.Role.StudentAddSINExpiry
          }, null, 8, ["allowedRole"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}