import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_title_value = _resolveComponent("title-value")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_input = _resolveComponent("v-input")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "editStudentProfileForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        title: "Edit Student Profile",
        "sub-title": "Enter the information to be updated below.",
        showDialog: _ctx.showDialog
      }, {
        content: _withCtx(() => [
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                "hide-details": "auto",
                label: "Given Names",
                modelValue: _ctx.formModel.givenNames,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.givenNames) = $event)),
                variant: "outlined",
                rules: [_ctx.checkGivenNameLengthRule],
                class: "mb-6"
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                "hide-details": "auto",
                label: "Last Name",
                modelValue: _ctx.formModel.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.lastName) = $event)),
                variant: "outlined",
                rules: [_ctx.checkLastNameLengthRule],
                class: "mb-6"
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                label: "Date of Birth",
                variant: "outlined",
                modelValue: _ctx.formModel.birthdate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.birthdate) = $event)),
                type: "date",
                max: _ctx.getTodaysDate(),
                "hide-details": "auto",
                class: "mb-6"
              }, null, 8, ["modelValue", "max"])
            ]),
            _: 1
          }),
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                "hide-details": "auto",
                label: "Email",
                modelValue: _ctx.formModel.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.email) = $event)),
                variant: "outlined",
                rules: [_ctx.checkEmailLengthRule, _ctx.checkEmailValidationRule],
                class: "mb-6"
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                label: "Notes",
                modelValue: _ctx.formModel.noteDescription,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.noteDescription) = $event)),
                variant: "outlined",
                rules: [_ctx.checkNotesLengthRule]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_title_value, null, {
            value: _withCtx(() => [
              _createVNode(_component_v_input, {
                rules: [_ctx.hasDataChangedValidationRule],
                "hide-details": "auto"
              }, null, 8, ["rules"])
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_footer_buttons, {
            processing: _ctx.loading,
            primaryLabel: "Update Profile",
            onPrimaryClick: _ctx.submit,
            onSecondaryClick: _ctx.cancel
          }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick"])
        ]),
        _: 1
      }, 8, ["showDialog"])
    ]),
    _: 1
  }, 512))
}