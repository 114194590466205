import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_formio = _resolveComponent("formio")!
  const _component_banner = _resolveComponent("banner")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_navigator, {
      title: "Back to student applications",
      routeLocation: {
      name: _ctx.AESTRoutesConst.STUDENT_APPLICATIONS,
      params: { studentId: _ctx.studentId },
    },
      subTitle: "Financial Aid Application"
    }, null, 8, ["routeLocation"]),
    _createVNode(_component_full_page_container, { class: "my-2" }, {
      default: _withCtx(() => [
        (_ctx.formName)
          ? (_openBlock(), _createBlock(_component_formio, {
              key: 0,
              formName: _ctx.formName,
              data: _ctx.formData,
              readOnly: true
            }, null, 8, ["formName", "data"]))
          : (_openBlock(), _createBlock(_component_banner, {
              key: 1,
              class: "mt-2",
              type: _ctx.BannerTypes.Warning,
              summary: "Supporting User has not submitted the application."
            }, null, 8, ["type"]))
      ]),
      _: 1
    })
  ], 64))
}