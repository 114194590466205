import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-bold-normal" }
const _hoisted_2 = { class: "label-value-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    type: _ctx.type,
    variant: "outlined",
    icon: _ctx.bannerIcon,
    class: "sims-banner"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.header), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "content", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.summary), 1)
                ]),
                (_ctx.summaryList)
                  ? _renderSlot(_ctx.$slots, "content-list", { key: 0 }, () => [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryList, (item, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
                        }), 128))
                      ])
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "actions")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["type", "icon"]))
}