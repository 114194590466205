import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-2" }
const _hoisted_2 = { class: "label-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_summary = _resolveComponent("error-summary")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "assessOfferingForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        showDialog: _ctx.showDialog,
        title: _ctx.title
      }, {
        content: _withCtx(() => [
          _createVNode(_component_error_summary, {
            errors: _ctx.assessOfferingForm.errors
          }, null, 8, ["errors"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
          ]),
          _createVNode(_component_v_textarea, {
            label: "Notes",
            placeholder: "Long text...",
            modelValue: _ctx.formModel.assessmentNotes,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.assessmentNotes) = $event)),
            variant: "outlined",
            rules: [_ctx.checkNotesLengthRule]
          }, null, 8, ["modelValue", "rules"])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.InstitutionApproveDeclineOffering
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_footer_buttons, {
                processing: _ctx.processing,
                primaryLabel: _ctx.primaryLabel,
                onPrimaryClick: _ctx.submit,
                onSecondaryClick: _ctx.cancel,
                disablePrimaryButton: notAllowed
              }, null, 8, ["processing", "primaryLabel", "onPrimaryClick", "onSecondaryClick", "disablePrimaryButton"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["showDialog", "title"])
    ]),
    _: 1
  }, 512))
}