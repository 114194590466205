import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_application_status_tracker_banner = _resolveComponent("application-status-tracker-banner")!

  return (_openBlock(), _createBlock(_component_application_status_tracker_banner, {
    label: "Draft application",
    content: "Your application has not been submitted to StudentAid BC yet. Please complete and submit your application when you are ready.",
    icon: "fa:fas fa-pencil-alt"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editApplication'))),
        disabled: !_ctx.areApplicationActionsAllowed
      }, {
        default: _withCtx(() => [
          _createTextVNode("Continue application ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}