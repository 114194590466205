import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_student_file_uploads = _resolveComponent("student-file-uploads")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_student_file_uploads, {
        studentId: _ctx.studentId,
        canUploadFiles: true,
        canDownloadFiles: true,
        onUploadFile: _ctx.uploadFile
      }, null, 8, ["studentId", "onUploadFile"])
    ]),
    _: 1
  }))
}