import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_c_logo = _resolveComponent("b-c-logo")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider_inset_opaque = _resolveComponent("v-divider-inset-opaque")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_IdleTimeChecker = _resolveComponent("IdleTimeChecker")!

  return (_openBlock(), _createBlock(_component_IdleTimeChecker, {
    clientIdType: _ctx.ClientIdType.Student
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { color: "white" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_c_logo, {
            subtitle: "Student Application",
            onClick: _ctx.logoClick
          }, null, 8, ["onClick"]),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn_toggle, {
            "selected-class": "active-btn label-bold",
            modelValue: _ctx.toggleNav,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toggleNav) = $event)),
            class: "navigation-btn float-left"
          }, {
            default: _withCtx(() => [
              (_ctx.hasAuthenticatedStudentAccount)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    class: "nav-item-label",
                    variant: "text",
                    to: {
            name: _ctx.StudentRoutesConst.STUDENT_DASHBOARD,
          }
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        class: "mb-1",
                        icon: "mdi-home-outline",
                        size: 30
                      })
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode("Home ")
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.hasAuthenticatedStudentAccount)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    class: "nav-item-label",
                    variant: "text",
                    to: {
            name: _ctx.StudentRoutesConst.STUDENT_APPLICATION_SUMMARY,
          },
                    "prepend-icon": "fa:far fa-folder"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Applications")
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.hasAuthenticatedStudentAccount)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    class: "nav-item-label",
                    variant: "text",
                    to: { name: _ctx.StudentRoutesConst.STUDENT_FILE_UPLOADER },
                    "prepend-icon": "fa:far fa-file-alt"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("File Uploader")
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.hasAuthenticatedStudentAccount)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 3,
                    class: "nav-item-label",
                    variant: "text",
                    to: { name: _ctx.StudentRoutesConst.STUDENT_REQUEST_CHANGE },
                    "prepend-icon": "fa:far fa-hand-paper"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Request a Change")
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              (_ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_v_menu, { key: 4 }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "mr-5 nav-item-label",
                        rounded: "xl",
                        icon: "fa:fa fa-user",
                        variant: "outlined",
                        elevation: "1",
                        color: "secondary"
                      }, props, { "aria-label": "Account" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, {
                        "active-class": "active-list-item",
                        density: "compact",
                        "bg-color": "default",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              _createVNode(_component_v_list_item, {
                                value: index,
                                onClick: item.command,
                                to: item.props?.to,
                                tabindex: "0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_1, _toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value", "onClick", "to"]),
                              (index < _ctx.menuItems.length - 1)
                                ? (_openBlock(), _createBlock(_component_v_divider_inset_opaque, { key: index }))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, { name: "sidebar" }),
      _createVNode(_component_v_main, { class: "body-background" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["clientIdType"]))
}