import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_offering = _resolveComponent("status-chip-offering")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_body_header, {
      title: "Study period offerings",
      recordsCount: _ctx.offeringsAndCount.count
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_row, { class: "m-0 p-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              density: "compact",
              label: "Search Offering Name",
              variant: "outlined",
              modelValue: _ctx.searchBox,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchBox) = $event)),
              "data-cy": "searchBox",
              onKeyup: _withKeys(_ctx.searchOfferingTable, ["enter"]),
              "prepend-inner-icon": "mdi-magnify",
              "hide-details": "auto"
            }, null, 8, ["modelValue", "onKeyup"]),
            (_ctx.allowOfferingEdit)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  class: "ml-2 float-right",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToAddNewOffering())),
                  color: "primary",
                  "prepend-icon": "fa:fa fa-plus-circle",
                  "data-cy": "addNewOfferingButton"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Add offering ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["recordsCount"]),
    _createVNode(_component_content_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_toggle_content, {
          toggled: !_ctx.offeringsAndCount.count
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DataTable, {
              value: _ctx.offeringsAndCount.results,
              lazy: true,
              paginator: true,
              rows: _ctx.DEFAULT_PAGE_LIMIT,
              rowsPerPageOptions: _ctx.PAGINATION_LIST,
              totalRecords: _ctx.offeringsAndCount.count,
              onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.paginationAndSortEvent($event))),
              onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.paginationAndSortEvent($event))),
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: _ctx.OfferingSummaryFields.OfferingName,
                  header: "Name",
                  sortable: true
                }, null, 8, ["field"]),
                _createVNode(_component_Column, {
                  field: _ctx.OfferingSummaryFields.YearOfStudy,
                  header: "Year of Study"
                }, null, 8, ["field"]),
                _createVNode(_component_Column, {
                  field: _ctx.OfferingSummaryFields.StudyDates,
                  header: "Study Dates"
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyStartDate)) + " - " + _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.studyEndDate)), 1)
                  ]),
                  _: 1
                }, 8, ["field"]),
                _createVNode(_component_Column, {
                  field: _ctx.OfferingSummaryFields.OfferingIntensity,
                  header: "Intensity"
                }, null, 8, ["field"]),
                _createVNode(_component_Column, {
                  field: "offeringType",
                  header: "Offering type"
                }),
                _createVNode(_component_Column, {
                  field: _ctx.OfferingSummaryFields.OfferingDelivered,
                  header: "Study delivery"
                }, null, 8, ["field"]),
                _createVNode(_component_Column, { header: "Status" }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_status_chip_offering, {
                      status: slotProps.data.offeringStatus
                    }, null, 8, ["status"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "Action" }, {
                  body: _withCtx((slotProps) => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      variant: "text",
                      onClick: ($event: any) => (_ctx.offeringButtonAction(slotProps.data.id)),
                      "append-icon": "mdi-pencil-outline"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.offeringActionLabel), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords", "loading"])
          ]),
          _: 1
        }, 8, ["toggled"])
      ]),
      _: 1
    })
  ], 64))
}