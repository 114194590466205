import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "mr-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_formio_modal_dialog = _resolveComponent("formio-modal-dialog")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "File Uploads",
        recordsCount: _ctx.studentFileUploads?.length
      }, _createSlots({ _: 2 }, [
        (_ctx.canUploadFiles)
          ? {
              name: "actions",
              fn: _withCtx(() => [
                _createVNode(_component_check_permission_role, {
                  role: _ctx.Role.StudentUploadFile
                }, {
                  default: _withCtx(({ notAllowed }) => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      "data-cy": "uploadFileButton",
                      onClick: _ctx.uploadFile,
                      "prepend-icon": "fa:fa fa-plus-circle",
                      class: "float-right",
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Upload file")
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"])
                  ]),
                  _: 1
                }, 8, ["role"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["recordsCount"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.studentFileUploads.length
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.studentFileUploads,
                paginator: true,
                totalRecords: _ctx.studentFileUploads?.length,
                rows: _ctx.DEFAULT_PAGE_LIMIT,
                rowsPerPageOptions: _ctx.PAGINATION_LIST
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "groupName",
                    header: "Document Purpose",
                    sortable: true
                  }),
                  _createVNode(_component_Column, {
                    field: "metadata",
                    header: "Application #"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(slotProps.data.metadata?.applicationNumber)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "updatedAt",
                    header: "Date Submitted"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(slotProps.data.updatedAt)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "updatedAt",
                    header: "File"
                  }, {
                    body: _withCtx((slotProps) => [
                      (_ctx.canDownloadFiles)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "file-label",
                            onClick: ($event: any) => (_ctx.fileUtils.downloadStudentDocument(slotProps.data))
                          }, [
                            _createElementVNode("span", _hoisted_2, [
                              _createVNode(_component_v_icon, {
                                icon: "fa:far fa-file-alt",
                                size: "20"
                              })
                            ]),
                            _createElementVNode("span", null, _toDisplayString(slotProps.data.fileName), 1)
                          ], 8, _hoisted_1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, [
                              _createVNode(_component_v_icon, {
                                icon: "fa:far fa-file-alt",
                                size: "20"
                              })
                            ]),
                            _createElementVNode("span", null, _toDisplayString(slotProps.data.fileName), 1)
                          ]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "totalRecords", "rows", "rowsPerPageOptions"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      }),
      _createVNode(_component_formio_modal_dialog, {
        ref: "fileUploadModal",
        title: "Upload file",
        formData: _ctx.initialData,
        formName: "uploadStudentDocumentsAEST"
      }, {
        actions: _withCtx(({ cancel, submit }) => [
          _createVNode(_component_v_row, { class: "m-0 p-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "primary",
                variant: "outlined",
                onClick: cancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_check_permission_role, {
                role: _ctx.Role.StudentUploadFile
              }, {
                default: _withCtx(({ notAllowed }) => [
                  _createVNode(_component_v_btn, {
                    class: "float-right",
                    onClick: submit,
                    color: "primary",
                    variant: "elevated",
                    disabled: notAllowed
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Upload now")
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disabled"])
                ]),
                _: 2
              }, 1032, ["role"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["formData"])
    ]),
    _: 1
  }))
}