import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "label-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_c_logo = _resolveComponent("b-c-logo")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_IdleTimeChecker = _resolveComponent("IdleTimeChecker")!

  return (_openBlock(), _createBlock(_component_IdleTimeChecker, {
    clientIdType: _ctx.ClientIdType.AEST
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { color: "white" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_c_logo, { subtitle: _ctx.MINISTRY_NAME }, null, 8, ["subtitle"]),
          _createVNode(_component_v_btn_toggle, {
            "selected-class": "active-btn label-bold",
            modelValue: _ctx.toggleNav,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toggleNav) = $event)),
            class: "navigation-btn float-left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_check_permission_role, {
                role: _ctx.Role.AESTCreateInstitution
              }, {
                default: _withCtx(({ notAllowed }) => [
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        class: "nav-item-label",
                        variant: "text",
                        to: {
                name: _ctx.AESTRoutesConst.AEST_DASHBOARD,
              }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Home")
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.isAuthenticated)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        variant: "text",
                        "prepend-icon": "fa:far fa-edit",
                        disabled: notAllowed,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (
                _ctx.$router.push({
                  name: _ctx.AESTRoutesConst.INSTITUTION_PROFILE_CREATE,
                })
              ))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Create institution")
                        ]),
                        _: 2
                      }, 1032, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["role"]),
              (_ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "nav-item-label",
                        rounded: "xl",
                        icon: "fa:fa fa-user",
                        variant: "outlined",
                        elevation: "1",
                        color: "secondary"
                      }, props, { "aria-label": "Account" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item, {
                            value: _ctx.menuItem.label,
                            onClick: _ctx.menuItem.command
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                onClick: _ctx.menuItem.command
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.menuItem.label), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }, 8, ["value", "onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, { name: "sidebar" }),
      _createVNode(_component_v_main, { class: "body-background" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["clientIdType"]))
}