import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_related_application_changed = _resolveComponent("related-application-changed")!
  const _component_application_status_tracker_banner = _resolveComponent("application-status-tracker-banner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.assessmentTriggerType === _ctx.AssessmentTriggerType.RelatedApplicationChanged
    )
      ? (_openBlock(), _createBlock(_component_related_application_changed, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_application_status_tracker_banner, {
      label: "Action required! Please accept your assessment",
      icon: "fa:fas fa-exclamation-triangle",
      "icon-color": "warning",
      "background-color": "warning-bg"
    }, {
      content: _withCtx(() => [
        _createTextVNode("Your assessment is ready! You must view and accept your assessment. Start by clicking “View assessment” in the table below. Your application will move to the enrolment stage after you accept your assessment. ")
      ]),
      _: 1
    })
  ], 64))
}