import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_institution_user_management = _resolveComponent("institution-user-management")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_modal_dialog_base = _resolveComponent("modal-dialog-base")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, { ref: "editUserForm" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_dialog_base, {
        showDialog: _ctx.showDialog,
        onDialogClosed: _ctx.dialogClosed,
        title: "Edit user",
        "data-cy": "editUserModal"
      }, {
        content: _withCtx(() => [
          _createVNode(_component_institution_user_management, {
            ref: "institutionUserManagement",
            errors: _ctx.editUserForm.errors,
            initialData: _ctx.initialData
          }, {
            "user-name": _withCtx(() => [
              _createVNode(_component_v_text_field, {
                "hide-details": "auto",
                modelValue: _ctx.userInfo.displayName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userInfo.displayName) = $event)),
                disabled: "",
                class: "mr-3 bceid-input",
                density: "compact",
                variant: "outlined",
                label: _ctx.userNameLabel,
                rules: [(v) => !!v || 'Basic BCeID user Id is required.']
              }, null, 8, ["modelValue", "label", "rules"])
            ]),
            _: 1
          }, 8, ["errors", "initialData"])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.InstitutionEditUser
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_footer_buttons, {
                processing: _ctx.processing,
                primaryLabel: "Edit user now",
                onPrimaryClick: _ctx.submit,
                onSecondaryClick: _ctx.cancel,
                disablePrimaryButton: notAllowed
              }, null, 8, ["processing", "onPrimaryClick", "onSecondaryClick", "disablePrimaryButton"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      }, 8, ["showDialog", "onDialogClosed"])
    ]),
    _: 1
  }, 512))
}