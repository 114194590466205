import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_request_assessment = _resolveComponent("request-assessment")!
  const _component_history_assessment = _resolveComponent("history-assessment")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "layout-template": "centered" }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Student applications",
        routeLocation: _ctx.backRoute,
        subTitle: "Assessment"
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_request_assessment, {
        class: "mb-5",
        applicationId: _ctx.applicationId,
        studentId: _ctx.studentId,
        onViewStudentAppeal: _ctx.goToStudentAppeal,
        onViewApplicationException: _ctx.goToApplicationException
      }, null, 8, ["applicationId", "studentId", "onViewStudentAppeal", "onViewApplicationException"]),
      _createVNode(_component_history_assessment, {
        applicationId: _ctx.applicationId,
        studentId: _ctx.studentId,
        viewRequestTypes: _ctx.assessmentRequestViewTypes,
        onViewStudentAppeal: _ctx.goToStudentAppeal,
        onViewAssessment: _ctx.gotToViewAssessment,
        onViewApplicationException: _ctx.goToApplicationException
      }, null, 8, ["applicationId", "studentId", "viewRequestTypes", "onViewStudentAppeal", "onViewAssessment", "onViewApplicationException"])
    ]),
    _: 1
  }))
}