import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.snackBarChoices.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackBarChoices.show) = $event)),
    variant: "text",
    color: _ctx.snackBarChoices.type,
    location: "top right",
    timeout: _ctx.snackBarChoices.displayTime,
    class: _normalizeClass([_ctx.snackBarClass, "mr-8"])
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_icon, {
        icon: "fa:fa fa-close",
        size: "23",
        class: "black-color",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snackBarChoices.show = false))
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "1" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: _ctx.snackBarIcon,
                  class: "icon-color mt-0",
                  size: "23"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "label-bold black-color" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.snackBarChoices.content), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "color", "timeout", "class"]))
}