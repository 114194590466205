import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_designation_agreement_form = _resolveComponent("designation-agreement-form")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Manage designations",
        subTitle: "View Designation",
        routeLocation: {
          name: _ctx.InstitutionRoutesConst.MANAGE_DESIGNATION,
        }
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_designation_agreement_form, {
        model: _ctx.designationModel,
        "view-only": true
      }, null, 8, ["model"])
    ]),
    _: 1
  }))
}