import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/paper_plane.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_application_status_tracker_banner = _resolveComponent("application-status-tracker-banner")!

  return (_openBlock(), _createBlock(_component_application_status_tracker_banner, {
    label: "Your application has been submitted!",
    icon: "fa:fas fa-check-circle",
    "icon-color": "success",
    content: "Keep up-to-date with your application by tracking it here."
  }, {
    image: _withCtx(() => [
      _createVNode(_component_v_img, {
        height: "60",
        alt: "A paper plane in the air. Illustration by Storyset.",
        src: _imports_0
      })
    ]),
    _: 1
  }))
}