import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_status_chip_restriction = _resolveComponent("status-chip-restriction")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_view_restriction_modal = _resolveComponent("view-restriction-modal")!
  const _component_add_student_restriction_modal = _resolveComponent("add-student-restriction-modal")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, { enableCardView: true }, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, { title: "All restrictions" }, _createSlots({ _: 2 }, [
        (_ctx.canAddRestrictions)
          ? {
              name: "actions",
              fn: _withCtx(() => [
                _createVNode(_component_check_permission_role, {
                  role: _ctx.Role.StudentAddRestriction
                }, {
                  default: _withCtx(({ notAllowed }) => [
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.addStudentRestriction,
                      class: "float-right",
                      color: "primary",
                      "data-cy": "addRestrictionButton",
                      "prepend-icon": "fa:fa fa-plus-circle",
                      disabled: notAllowed
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Add restriction")
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"])
                  ]),
                  _: 1
                }, 8, ["role"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.studentRestrictions?.length,
            message: "No records found."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.studentRestrictions,
                paginator: true,
                rows: _ctx.DEFAULT_PAGE_LIMIT,
                rowsPerPageOptions: _ctx.PAGINATION_LIST
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "restrictionCategory",
                    header: "Category",
                    sortable: true
                  }),
                  _createVNode(_component_Column, {
                    field: "description",
                    header: "Reason"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(`${slotProps.data.restrictionCode} - ${slotProps.data.description}`), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "createdAt",
                    header: "Added"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(slotProps.data.createdAt)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "updatedAt",
                    header: "Resolved"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(_ctx.conditionalEmptyStringFiller(
                !slotProps.data.isActive,
                _ctx.dateOnlyLongString(slotProps.data.updatedAt),
              )), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "isActive",
                    header: "Status"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_status_chip_restriction, {
                        status: 
                  slotProps.data.isActive
                    ? _ctx.RestrictionStatus.Active
                    : _ctx.RestrictionStatus.Resolved
                
                      }, null, 8, ["status"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "restrictionId",
                    header: ""
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        variant: "outlined",
                        onClick: ($event: any) => (_ctx.viewStudentRestriction(slotProps.data.restrictionId))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "rowsPerPageOptions"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      }),
      _createVNode(_component_view_restriction_modal, {
        ref: "viewRestriction",
        restrictionData: _ctx.studentRestriction,
        allowedRole: _ctx.Role.StudentResolveRestriction,
        canResolveRestriction: _ctx.canResolveRestriction
      }, null, 8, ["restrictionData", "allowedRole", "canResolveRestriction"]),
      (_ctx.canAddRestrictions)
        ? (_openBlock(), _createBlock(_component_add_student_restriction_modal, {
            key: 0,
            ref: "addRestriction",
            entityType: _ctx.RestrictionEntityType.Student,
            allowedRole: _ctx.Role.StudentAddRestriction
          }, null, 8, ["entityType", "allowedRole"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}