import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_offering_form = _resolveComponent("offering-form")!
  const _component_assess_offering_modal = _resolveComponent("assess-offering-modal")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Study period offerings",
        routeLocation: _ctx.programRoute,
        subTitle: "View Request"
      }, _createSlots({ _: 2 }, [
        (_ctx.showActionButtons)
          ? {
              name: "buttons",
              fn: _withCtx(() => [
                _createVNode(_component_v_row, { class: "m-0 p-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_check_permission_role, {
                      role: _ctx.Role.InstitutionApproveDeclineOffering
                    }, {
                      default: _withCtx(({ notAllowed }) => [
                        _createVNode(_component_v_btn, {
                          variant: "outlined",
                          disabled: notAllowed,
                          color: "primary",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.assessOffering(_ctx.OfferingStatus.CreationDeclined)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Decline")
                          ]),
                          _: 2
                        }, 1032, ["disabled"]),
                        _createVNode(_component_v_btn, {
                          class: "ml-2",
                          color: "primary",
                          disabled: notAllowed,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.assessOffering(_ctx.OfferingStatus.Approved)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Approve offering")
                          ]),
                          _: 2
                        }, 1032, ["disabled"])
                      ]),
                      _: 1
                    }, 8, ["role"])
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["routeLocation"]),
      _createVNode(_component_program_offering_detail_header, {
        class: "m-4",
        headerDetails: {
          ..._ctx.initialData,
          status: _ctx.initialData.offeringStatus,
        }
      }, null, 8, ["headerDetails"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_offering_form, { data: _ctx.initialData }, null, 8, ["data"]),
      _createVNode(_component_assess_offering_modal, {
        ref: "assessOfferingModalRef",
        offeringStatus: _ctx.offeringApprovalStatus
      }, null, 8, ["offeringStatus"])
    ]),
    _: 1
  }))
}