import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-text-capitalize" }
const _hoisted_2 = { class: "p-text-capitalize" }
const _hoisted_3 = { class: "p-text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_body_header = _resolveComponent("body-header")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_input = _resolveComponent("v-input")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Search Institution",
        subTitle: "Look up an institution by entering their information below."
      }),
      _createVNode(_component_v_form, { ref: "searchInstitutionsForm" }, {
        default: _withCtx(() => [
          _createVNode(_component_content_group, { class: "mb-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        label: "Legal name",
                        variant: "outlined",
                        modelValue: _ctx.legalName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.legalName) = $event)),
                        "data-cy": "legalName",
                        onKeyup: _withKeys(_ctx.searchInstitutions, ["enter"]),
                        "hide-details": ""
                      }, null, 8, ["modelValue", "onKeyup"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        label: "Operating name",
                        variant: "outlined",
                        modelValue: _ctx.operatingName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.operatingName) = $event)),
                        "data-cy": "operatingName",
                        onKeyup: _withKeys(_ctx.searchInstitutions, ["enter"]),
                        "hide-details": ""
                      }, null, 8, ["modelValue", "onKeyup"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        "data-cy": "searchInstitutions",
                        onClick: _ctx.searchInstitutions
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Search")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_input, {
                rules: [_ctx.isValidSearch()],
                "hide-details": "auto",
                error: ""
              }, null, 8, ["rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      (_ctx.institutionsFound)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_body_header, { title: "Results" }),
            _createVNode(_component_content_group, null, {
              default: _withCtx(() => [
                (_ctx.institutionsFound)
                  ? (_openBlock(), _createBlock(_component_DataTable, {
                      key: 0,
                      class: "mt-4",
                      autoLayout: true,
                      scrollable: true,
                      value: _ctx.institutions
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "operatingName",
                          header: "Operating Name",
                          sortable: true
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(slotProps.data.operatingName), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "legalName",
                          header: "Legal Name",
                          sortable: true
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_2, _toDisplayString(slotProps.data.legalName), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "address",
                          header: "Address"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getFormattedAddress(slotProps.data.address)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, { header: "Action" }, {
                          body: _withCtx((slotProps) => [
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              "data-cy": "viewInstitution",
                              onClick: ($event: any) => (_ctx.goToViewInstitution(slotProps.data.id))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("View")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}