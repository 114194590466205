import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_program_offering_detail_header = _resolveComponent("program-offering-detail-header")!
  const _component_banner = _resolveComponent("banner")!
  const _component_offering_form_submit = _resolveComponent("offering-form-submit")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Edit Offering",
        routeLocation: _ctx.editLocationOfferingsRoute,
        subTitle: "Request to Change"
      }, null, 8, ["routeLocation"]),
      (_ctx.offeringId)
        ? (_openBlock(), _createBlock(_component_program_offering_detail_header, {
            key: 0,
            class: "m-4",
            headerDetails: {
          ..._ctx.initialData,
          status: _ctx.initialData.offeringStatus,
        }
          }, null, 8, ["headerDetails"]))
        : _createCommentVNode("", true)
    ]),
    alerts: _withCtx(() => [
      _createVNode(_component_banner, {
        class: "mb-2",
        type: _ctx.BannerTypes.Warning,
        header: "You're requesting a change when students have applied financial aid for this offering",
        summary: "Please be advised if the request is approved, the students who applied for financial aid for this offering will go through a reassessment and it may change their funding amount."
      }, null, 8, ["type"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_offering_form_submit, {
        submitLabel: "Request a change now",
        data: _ctx.initialData,
        formMode: _ctx.OfferingFormModes.Editable,
        locationId: _ctx.locationId,
        programId: _ctx.programId,
        onSubmit: _ctx.submit,
        onCancel: _ctx.goToEditLocationOfferings
      }, null, 8, ["data", "formMode", "locationId", "programId", "onSubmit", "onCancel"])
    ]),
    _: 1
  }))
}