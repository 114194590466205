import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_scholastic_standing_limited_history = _resolveComponent("student-scholastic-standing-limited-history")!
  const _component_tab_container = _resolveComponent("tab-container")!
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_student_page_container = _resolveComponent("student-page-container")!

  return (_openBlock(), _createBlock(_component_student_page_container, {
    "layout-template": "centered",
    "hide-restriction": "true"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Home",
        subTitle: "Account Activity",
        routeLocation: { name: _ctx.StudentRoutesConst.STUDENT_DASHBOARD }
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tab_container, {
        enableCardView: false,
        class: "mb-6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_student_scholastic_standing_limited_history)
        ]),
        _: 1
      }),
      _createVNode(_component_formio_container, {
        formName: "studentAccountActivity",
        formData: _ctx.initialData
      }, null, 8, ["formData"])
    ]),
    _: 1
  }))
}